import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, CardBody, Row, Col, Tooltip } from "reactstrap";

import { MUIIcons } from "../../AbstractElements";
import "./style.scss";
import {
  CertifiedOn,
  ComingSoon,
  ExpiredOn,
  RenewCertification,
} from "../../utils/Constant";
import {
  formatCustomDate,
  isDateLessThanOrEqualToToday,
  showToast,
} from "../../utils/helper/helper";

interface CertificationListContainerProps {
  uniqueKey?: string;
  title: string;
  certifiedOn: string;
  expiredOn: string;
}

const CertificationListContainer: React.FC<CertificationListContainerProps> = ({
  uniqueKey,
  title,
  certifiedOn,
  expiredOn,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const isEqual = isDateLessThanOrEqualToToday(expiredOn);

  const handleCertificateDownload = () => {
    showToast(ComingSoon, "warning");
  };

  const handleCertification = () => {
    showToast(ComingSoon, "warning");
  };

  return (
    <Card className="p-3 custom-certifcation-card">
      <CardBody>
        <Row className="align-items-center">
          <Col
            md="2"
            xs="7"
            className="d-flex align-items-center gap-2 order-1 order-md-1 custom-header-col"
          >
            <MUIIcons
              className="primary-icon-color"
              iconName="CardMembershipOutlined"
              size={25}
            />
            <span id={`tool_tip${uniqueKey}`} className="custom-heading">
              {title}
            </span>
            <Tooltip
              isOpen={tooltipOpen}
              target={`tool_tip${uniqueKey}`}
              toggle={toggleTooltip}
            >
              {title}
            </Tooltip>
          </Col>
          <Col
            md="3"
            xs="7"
            className="d-flex align-items-center gap-2 mt-3 mt-md-0 flex-grow-1 order-3 order-md-2"
          >
            <div className="custom-certifiedOn">
              <MUIIcons iconName={"CalendarTodayOutlined"} size={20} />
              <span>
                {CertifiedOn} : {formatCustomDate(certifiedOn)}
              </span>
            </div>
          </Col>
          <Col
            md="4"
            xs="9"
            className="d-flex align-items-center gap-2 mt-3 mt-md-0 order-4 order-md-3"
          >
            {isEqual && expiredOn && (
              <span className="custom-expired">
                {ExpiredOn} : {formatCustomDate(expiredOn)}
              </span>
            )}
          </Col>
          <Col
            md="3"
            xs="5"
            className="d-flex justify-content-end order-2 order-md-4"
          >
            {!isEqual ? (
              <MUIIcons
                className="primary-icon-color"
                iconName={"Download"}
                size={25}
                onClick={handleCertificateDownload}
              />
            ) : (
              <div
                onClick={handleCertification}
                className="p-2 custom-re-certification pointer"
              >
                <p className="primary-text-color">{RenewCertification}</p>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

CertificationListContainer.propTypes = {
  title: PropTypes.string,
  expiredOn: PropTypes.string,
  certifiedOn: PropTypes.string,
};

export default CertificationListContainer;
