import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";

import { Btn, H5, P } from "../../../../../AbstractElements";
import SimpleAccordion from "../../../../../CommonElements/Accordion";
import Divider from "../../../../../CommonElements/Divider";
import FileHandler from "../../../../../CommonElements/FileHandler";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import CommonModal from "../../../../../CommonElements/Modal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  endLearnerAssessment,
  startLearnerAssessment,
} from "../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  Close,
  Competent,
  Confirm,
  Confirmation,
  LearnerResponses,
  NotCompetent,
  PartialCompetent,
  PleaseAnswerAllQuestions,
  Questions,
  RMResponses,
  Submit,
  SubmitAssessmentAlert,
} from "../../../../../utils/Constant";
import { showToast } from "../../../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LEARNER_COMPETENCY_ASSESSMENT,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "../../../../../utils/helper/localStorageutils";
import Header from "../../../../LearningAssessment/Header";
import "./style.scss";

const CompetencyAssessment = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { participantId } = location.state || "";
  const { learnerAssesmentQuestions } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [learnerResponses, setLearnerResponses] = useState({});
  const [trainerResponses, setTrainerResponses] = useState({});
  const [accordionData, setAccordionData] = useState([]);
  const dataExist = getFromLocalStorage(
    LEARNER_COMPETENCY_ASSESSMENT + participantId
  );
  const [dataUpdated, setDataUpdated] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  useEffect(() => {
    if (participantId && !dataExist) {
      dispatch(startLearnerAssessment({ id: participantId }));
    } else if (dataExist) {
      const modules = {};
      dataExist?.questions?.forEach(item => {
        if (!modules[item?.module_id]) {
          modules[item?.module_id] = {
            id: item?.module_id,
            title: item?.module_name,
            questions: [item],
          };
        } else {
          modules[item?.module_id].questions.push(item);
        }
      });

      const accordionData = Object.values(modules).map((module: any) => ({
        id: module?.id,
        accordionItemClass: "item-class",
        accordionHeaderClass: "header-class",
        spanClass: "span-class",
        accordionHeading: module.title,
        icon: true,
        bodyText: renderModule(module),
      }));
      setDataUpdated(false);
      setAccordionData(accordionData);
    }
  }, [
    participantId,
    dispatch,
    dataUpdated,
    learnerResponses,
    trainerResponses,
  ]);

  useEffect(() => {
    const dataExist = getFromLocalStorage(
      LEARNER_COMPETENCY_ASSESSMENT + participantId
    );
    if (learnerAssesmentQuestions && !dataExist) {
      const modules = {};
      learnerAssesmentQuestions?.questions?.forEach(item => {
        if (!modules[item?.module_id]) {
          modules[item?.module_id] = {
            id: item?.module_id,
            title: item?.module_name,
            questions: [item],
          };
        } else {
          modules[item?.module_id].questions.push(item);
        }
      });

      const accordionData = Object.values(modules).map((module: any) => ({
        id: module?.id,
        accordionItemClass: "item-class",
        accordionHeaderClass: "header-class",
        spanClass: "span-class",
        accordionHeading: module.title,
        icon: true,
        bodyText: renderModule(module),
      }));

      setAccordionData(accordionData);

      if (learnerAssesmentQuestions?.questions) {
        saveToLocalStorage(
          LEARNER_COMPETENCY_ASSESSMENT + participantId,
          learnerAssesmentQuestions
        );
      }
    }

    const storedResponses = getFromLocalStorage(
      LEARNER_COMPETENCY_ASSESSMENT + participantId + "_responses"
    );
    if (storedResponses) {
      setLearnerResponses(storedResponses);
    }
  }, [learnerAssesmentQuestions, participantId]);

  const handleCheckboxChange = (moduleId, questionId, responseType, value) => {
    if (responseType === "learner") {
      setLearnerResponses(prevState => {
        const newState = {
          ...prevState,
          [moduleId]: {
            ...prevState[moduleId],
            [questionId]: value,
          },
        };

        saveToLocalStorage(
          LEARNER_COMPETENCY_ASSESSMENT + participantId + "_responses",
          newState
        );

        return newState;
      });
    } else if (responseType === "trainer") {
      setTrainerResponses(prevState => {
        const newState = {
          ...prevState,
          [moduleId]: {
            ...prevState[moduleId],
            [questionId]: value,
          },
        };
        return newState;
      });
    }
    setDataUpdated(true);
  };

  const renderCheckboxGroup = (moduleId, questionId, responseType) => {
    const responseValue =
      responseType === "learner"
        ? learnerResponses[moduleId]?.[questionId]
        : trainerResponses[moduleId]?.[questionId];

    return (
      <>
        <div className="form-check text-dark">
          <Input
            type="checkbox"
            className="custom-checkbox"
            checked={responseValue === Competent}
            onChange={() =>
              handleCheckboxChange(
                moduleId,
                questionId,
                responseType,
                Competent
              )
            }
          />
          <Label check>{Competent}</Label>
        </div>
        <div className="form-check text-dark">
          <Input
            type="checkbox"
            className="custom-checkbox"
            checked={responseValue === NotCompetent}
            onChange={() =>
              handleCheckboxChange(
                moduleId,
                questionId,
                responseType,
                NotCompetent
              )
            }
          />
          <Label check>{NotCompetent}</Label>
        </div>
        <div className="form-check text-dark">
          <Input
            type="checkbox"
            className="custom-checkbox"
            checked={responseValue === PartialCompetent}
            onChange={() =>
              handleCheckboxChange(
                moduleId,
                questionId,
                responseType,
                PartialCompetent
              )
            }
          />
          <Label check>{PartialCompetent}</Label>
        </div>
      </>
    );
  };

  const renderModule = module => (
    <>
      <Card className="generic-module-card">
        <CardBody className="m-2">
          <Row>
            <Col lg={participantId ? 7 : 4}>
              <P className="fw-bold">{Questions}</P>
            </Col>
            <Col>
              <P className="fw-bold">{LearnerResponses}</P>
            </Col>
            {!participantId && (
              <Col>
                <P className="fw-bold">{RMResponses}</P>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      {module?.questions?.map(question => {
        return (
          <React.Fragment key={question?.question_id}>
            <Row className="mt-3">
              <Col lg={participantId ? 6 : 4}>{question?.question_text}</Col>
              <Col lg={1}>
                {question?.media_url ? (
                  <FileHandler showIcon mediaUrl={question?.media_url} />
                ) : null}
              </Col>
              <Col className="d-flex gap-3">
                {renderCheckboxGroup(1, question.question_id, "learner")}
              </Col>
              {!participantId && (
                <Col className="d-flex gap-3">
                  {renderCheckboxGroup(1, question.question_id, "trainer")}
                </Col>
              )}
            </Row>
            <Divider />
          </React.Fragment>
        );
      })}
    </>
  );

  const onSubmit = async () => {
    const answered = [];
    const competencyAnswers = {
      [Competent]: "competent",
      [NotCompetent]: "not_competent",
      [PartialCompetent]: "partial_competent",
    };
    Object.keys(learnerResponses)?.forEach(item => {
      Object.keys(learnerResponses[item])?.forEach(answer => {
        answered.push({
          question_id: answer,
          answer_text: competencyAnswers[learnerResponses[item][answer]],
        });
      });
    });

    const payload = {
      participant_id: participantId,
      responses: answered,
    };
    const response = await dispatch(endLearnerAssessment({ data: payload }));
    hanldeConfirmModal();
    if (response?.payload) {
      removeFromLocalStorage(LEARNER_COMPETENCY_ASSESSMENT + participantId);
      removeFromLocalStorage(
        LEARNER_COMPETENCY_ASSESSMENT + participantId + "_responses"
      );
      return navigate(`${process.env.PUBLIC_URL}/assessment-list`);
    }
  };

  const handleValidate = () => {
    const answered = [];
    const competencyAnswers = {
      [Competent]: "competent",
      [NotCompetent]: "not_competent",
      [PartialCompetent]: "partial_competent",
    };
    Object.keys(learnerResponses)?.forEach(item => {
      Object.keys(learnerResponses[item])?.forEach(answer => {
        answered.push({
          question_id: answer,
          answer_text: competencyAnswers[learnerResponses[item][answer]],
        });
      });
    });

    if (dataExist?.questions?.length !== answered?.length) {
      showToast(PleaseAnswerAllQuestions, "error");
      return;
    }
    hanldeConfirmModal();
  };

  const hanldeConfirmModal = () => {
    setIsConfirmModal(prevState => !prevState);
  };

  return (
    <div className="page-body pb-5">
      <Header />
      <CommonModal
        sizeTitle={Confirmation}
        modalBodyClassName=""
        isOpen={isConfirmModal}
        toggle={hanldeConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={onSubmit}
        primaryBtnText={Confirm}
      >
        {SubmitAssessmentAlert}
      </CommonModal>
      <div className="competency-assessment-modules-padding m-4 mb-5">
        {accordionData && <SimpleAccordion accordionList={accordionData} />}
      </div>
      <div>
        <FixedFooter>
          <Link to="/assessment-list">
            <div className="edit-details-button">
              <H5 className="primary-text-color pointer">{Close}</H5>
            </div>
          </Link>

          <Btn color="primary" onClick={handleValidate}>
            {Submit}
          </Btn>
        </FixedFooter>
      </div>
    </div>
  );
};

export default CompetencyAssessment;
