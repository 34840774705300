import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  ADD_COMMENT,
  ADD_COMMENT_URL,
  CREATE_COURSE_CONTENT,
  CREATE_COURSE_CONTENT_API,
  DOWNLOAD_COURSE_STAGE_DATA_API,
  DOWNLOAD_STAGE_DATA,
  GET,
  GET_COURSE_CONTENT,
  GET_COURSE_CONTENT_API,
  GET_COURSE_LIST,
  GET_COURSE_LIST_API,
  GET_PUBLISHED_COURSE_LIST,
  GET_PUBLISHED_COURSE_LIST_API,
  POST,
  PUT,
  REVIEW_COURSE,
  REVIEW_COURSE_API,
  SUBMIT_COURSE_DETAILS_BY_TRAINER,
  SUBMIT_COURSE_DETAILS_BY_TRAINER_URL,
  UPDATE_COURSE_CONTENT,
  UPDATE_COURSE_CONTENT_API,
  UPDATE_COMMENT_STATUS_URL,
  UPDATE_COMMENT_STATUS,
  GET_COURSE_CONTENT_BY_IDS_API,
  GET_COURSE_CONTENT_BY_IDS,
  GET_USER_SPECIFIC_COURSE_LIST,
  GET_USER_SPECIFIC_COURSE_LIST_API,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../utils/helper/localStorageutils";

interface CourseInterface {
  courseList: CourseListResponse;
  publishedCourseList: CourseListResponse;
  courseContent: Course;
  loading: boolean;
  error: string | null;
  courseContentByIds: CourseContentByIdsResponse[];
  userSpecificCourseList: CourseListResponse;
}

const initialState: CourseInterface = {
  courseList: { course_list: [] },
  userSpecificCourseList: { course_list: [] },
  publishedCourseList: { course_list: [] },
  courseContent: null,
  loading: false,
  error: null,
  courseContentByIds: [],
};

export const downloadFile = (id: any, type: string, fileName: string) => {
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const courseApiUrl = `${process.env.REACT_APP_COURSE_API_ENDPOINT}`;
  fetch(
    `${courseApiUrl}/course?request_type=course-content&id=${id}&type=${type}`,
    {
      method: GET,
      headers: { "x-access-token": loginData.accessToken },
    }
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName.replaceAll(" ", "_")}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error("Error downloading file:", error);
    });
};

export const getCourseList = createAsyncThunk(
  GET_COURSE_LIST,
  async ({
    status,
    course_type,
    domain,
  }: {
    status: string[];
    course_type: string[];
    domain: string[] | null;
  }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_LIST_API(
        status.toString(),
        course_type.toString(),
        domain ? domain.toString() : null
      )
    );
    return response.data;
  }
);

export const getUserSpecificCourseList = createAsyncThunk(
  GET_USER_SPECIFIC_COURSE_LIST,
  async ({
    status,
    course_type,
    user_id,
  }: {
    status: string[];
    course_type: string[];
    user_id: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_USER_SPECIFIC_COURSE_LIST_API(
        status.toString(),
        course_type.toString(),
        user_id?.toString()
      )
    );
    return response.data;
  }
);

export const downloadCourseStageDataApi = createAsyncThunk(
  DOWNLOAD_STAGE_DATA,
  async ({ id, type }: { id: string; type: string }) => {
    const response = await apiRequest(
      GET,
      DOWNLOAD_COURSE_STAGE_DATA_API(id, type)
    );
    return response;
  }
);

export const getCourseContent = createAsyncThunk(
  GET_COURSE_CONTENT,
  async ({ id }: { id: string }) => {
    const response = await apiRequest(GET, GET_COURSE_CONTENT_API(id));
    return response.data;
  }
);

export const getPublishedCourseList = createAsyncThunk(
  GET_PUBLISHED_COURSE_LIST,
  async () => {
    const response = await apiRequest(GET, GET_PUBLISHED_COURSE_LIST_API());
    return response.data;
  }
);

export const createCourseContent = createAsyncThunk(
  CREATE_COURSE_CONTENT,
  async ({ courseContent }: { courseContent: CourseDetailsPayload }) => {
    const response = await apiRequest(
      POST,
      CREATE_COURSE_CONTENT_API(),
      courseContent
    );
    return response;
  }
);

export const updateCourseContent = createAsyncThunk(
  UPDATE_COURSE_CONTENT,
  async ({ courseContent }: { courseContent: any }) => {
    const response = await apiRequest(
      PUT,
      UPDATE_COURSE_CONTENT_API(),
      courseContent
    );
    return response;
  }
);

export const addCommentToStages = createAsyncThunk(
  ADD_COMMENT,
  async ({ comment }: { comment: any }) => {
    const response = await apiRequest(PUT, ADD_COMMENT_URL(), comment);
    return response;
  }
);

export const updateCommentStatus = createAsyncThunk(
  UPDATE_COMMENT_STATUS,
  async ({ correspondingStatus }: { correspondingStatus: any }) => {
    const response = await apiRequest(
      POST,
      UPDATE_COMMENT_STATUS_URL(),
      correspondingStatus
    );
    return response;
  }
);

export const reviewCourse = createAsyncThunk(
  REVIEW_COURSE,
  async ({ courseContent }: { courseContent: any }) => {
    const response = await apiRequest(PUT, REVIEW_COURSE_API(), courseContent);
    return response;
  }
);

export const submitCourseDetailsByTrainer = createAsyncThunk(
  SUBMIT_COURSE_DETAILS_BY_TRAINER,
  async ({ courseContent }: { courseContent: any }) => {
    const response = await apiRequest(
      PUT,
      SUBMIT_COURSE_DETAILS_BY_TRAINER_URL(),
      courseContent
    );
    return response;
  }
);

export const getCourseContentByIds = createAsyncThunk(
  GET_COURSE_CONTENT_BY_IDS,
  async ({ id }: { id: number[] }) => {
    const response = await apiRequest(GET, GET_COURSE_CONTENT_BY_IDS_API(id));
    return response.data;
  }
);

const CourseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setBasicInputFormValue(state, action) {
      state.loading = action.payload;
    },
    setCourseContentToNull(state) {
      state.courseContent = null;
    },
    setCourseListToNull(state) {
      state.courseList = initialState.courseList;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(downloadCourseStageDataApi.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadCourseStageDataApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(downloadCourseStageDataApi.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(reviewCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reviewCourse.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(reviewCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(submitCourseDetailsByTrainer.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitCourseDetailsByTrainer.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(submitCourseDetailsByTrainer.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(addCommentToStages.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCommentToStages.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(addCommentToStages.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateCommentStatus.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCommentStatus.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateCommentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || SomethingWentWrong;
      });

    builder
      .addCase(createCourseContent.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCourseContent.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createCourseContent.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateCourseContent.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCourseContent.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateCourseContent.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseList.fulfilled,
        (state, action: PayloadAction<CourseListResponse>) => {
          state.loading = false;
          state.courseList = action.payload;
        }
      )
      .addCase(getCourseList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getUserSpecificCourseList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUserSpecificCourseList.fulfilled,
        (state, action: PayloadAction<CourseListResponse>) => {
          state.loading = false;
          state.userSpecificCourseList = action.payload;
        }
      )
      .addCase(getUserSpecificCourseList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseContent.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseContent.fulfilled,
        (state, action: PayloadAction<Course>) => {
          state.loading = false;
          state.courseContent = action.payload;
        }
      )
      .addCase(getCourseContent.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getPublishedCourseList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPublishedCourseList.fulfilled,
        (state, action: PayloadAction<CourseListResponse>) => {
          state.loading = false;
          state.publishedCourseList = action.payload;
        }
      )
      .addCase(getPublishedCourseList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseContentByIds.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseContentByIds.fulfilled,
        (state, action: PayloadAction<CourseContentByIdsResponse[]>) => {
          state.loading = false;
          state.courseContentByIds = action.payload;
        }
      )
      .addCase(getCourseContentByIds.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const {
  setBasicInputFormValue,
  setCourseContentToNull,
  setCourseListToNull,
} = CourseSlice.actions;
export default CourseSlice.reducer;
