import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from "../Componant/Authentication/Login";
import CompetencyAssessment from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/CompetencyAssessments";
import AssessmentDetails from "../Pages/AssessmentDetails";
import LearningAssessment from "../Pages/LearningAssessment";
import AssessmentInstruction from "../Pages/LearningAssessment/AssessmentInstruction";
import { useAppDispatch } from "../ReduxToolkit/Hooks";
import { setSideBarMenuItems } from "../ReduxToolkit/Reducers/LayoutSlice";
import { syncScormData } from "../ReduxToolkit/Reducers/LearnerSlice";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
  OPENED_SCORM_DETAILS,
  removeFromLocalStorage,
} from "../utils/helper/localStorageutils";

import LayoutRoutes from "./LayoutRoutes";
import PrivateRoute from "./PrivateRoute";

const RouterData = () => {
  const login = getFromLocalStorage(LOGGED_IN_USER);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSideBarMenuItems());
    window.addEventListener("syncScormData", handleCustomEvent);
    return () => {
      removeScormListener();
    };
  }, []);

  const removeScormListener = () => {
    window.removeEventListener("syncScormData", handleCustomEvent);
  };

  const handleCustomEvent = async (event: Event) => {
    const customEvent = event as CustomEvent;
    if (customEvent?.detail?.message === "LMSFinish") {
      const courseDetails = getFromLocalStorage(OPENED_SCORM_DETAILS);
      if (courseDetails) {
        await dispatch(syncScormData({ scormContent: courseDetails }));
        removeFromLocalStorage(OPENED_SCORM_DETAILS);
      }
    }
  };
  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        {login ? (
          <Route
            path={`${process.env.PUBLIC_URL}` || "/"}
            element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />}
          />
        ) : (
          ""
        )}
        <Route path={"/"} element={<PrivateRoute />}>
          <Route path={"/*"} element={<LayoutRoutes />} />
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route
          path={`${process.env.PUBLIC_URL}/assessment-instruction`}
          element={<AssessmentInstruction />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/learning-assessment`}
          element={<LearningAssessment />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/self-assessment`}
          element={<CompetencyAssessment />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/assessment-details`}
          element={<AssessmentDetails />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
