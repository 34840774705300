import AssessmentCatlogList from "../Pages/Assessment/AssessmentCatlog";
import AddAssessmentCatlog from "../Pages/Assessment/AssessmentCatlog/AddAssessmentCatlog";
import AssessmentCatlogDetails from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails";
import ScheduleAssessmentsCatlog from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails/ScheduleAssessment";
import ScheduledUserDetails from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails/ScheduledUserDetails";
import LearnerAssessmets from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments";
import CompetencyAssessmentDetails from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/CompetencyAssessmentDetail";
import CompetencyAssessment from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/CompetencyAssessments";
import ReviewCompetencyAssessment from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/ReviewCompetencyAssessment";
import ReviewStandaloneAssessments from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/ReviewStandaloneAssessments";
import ScheduledAssessments from "../Pages/Assessment/ScheduledAssessments";
import AssessmentList from "../Pages/AssessmentList";
import AssessmentListDetails from "../Pages/AssessmentList/AssessmentListDetails";
import Attempts from "../Pages/AssessmentList/AssessmentListDetails/Attempts";
import ComingSoon from "../Pages/ComingSoon";
import CourseList from "../Pages/CourseMangement/CourseList";
import CourseDetailedView from "../Pages/CourseMangement/CourseList/CourseStageDetails";
import AdditionalInformation from "../Pages/CourseMangement/CourseList/CourseStageDetails/AdditionalInformation";
import CreateAdditionalInformation from "../Pages/CourseMangement/CourseList/CourseStageDetails/AdditionalInformation/CreateAdditionalInformation";
import Assessment from "../Pages/CourseMangement/CourseList/CourseStageDetails/Assessment";
import CreateAssessment from "../Pages/CourseMangement/CourseList/CourseStageDetails/Assessment/CreateAssessment";
import CompetancyStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/CompetancyStatement";
import CreateCompetencyStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/CompetancyStatement/CreateCompetencyStatement";
import CourseMaterials from "../Pages/CourseMangement/CourseList/CourseStageDetails/CourseMaterials";
import CreateMaterials from "../Pages/CourseMangement/CourseList/CourseStageDetails/CourseMaterials/CreateMaterials";
import LessonPlan from "../Pages/CourseMangement/CourseList/CourseStageDetails/LessonPlan";
import CreateLessonPlan from "../Pages/CourseMangement/CourseList/CourseStageDetails/LessonPlan/CreateLessonPlan";
import LODetails from "../Pages/CourseMangement/CourseList/CourseStageDetails/LO";
import CreateLO from "../Pages/CourseMangement/CourseList/CourseStageDetails/LO/CreateLo";
import TrainerBondDetails from "../Pages/CourseMangement/CourseList/CourseStageDetails/TrainerBondDetails";
import WebinarCompetativeStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/WebinarCompetencyStatement";
import CreateWebinarCompetativeStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/WebinarCompetencyStatement/createWebinarCompetencyStatement";
import CreateBasicCourseDetails from "../Pages/CourseMangement/CourseList/CreateBasicDetails";
import RequestList from "../Pages/CourseMangement/RequestList";
import CourseCreation from "../Pages/CourseMangement/RequestList/RaiseNewRequest";
import RequestDetails from "../Pages/CourseMangement/RequestList/RequestDetails";
import AddCurriculum from "../Pages/Curriculum/AddCurriculum";
import CurriculumList from "../Pages/Curriculum/CurriculumList";
import SamplePage from "../Pages/Dashboard/SamplePage";
import QuestionBankList from "../Pages/MasterData/QuestionBank";
import AddQuestionBank from "../Pages/MasterData/QuestionBank/AddQuestionBank";
import QuestionBankPreview from "../Pages/MasterData/QuestionBank/QuestionBankPreview";
import MyLearningPlan from "../Pages/MyLearningPlan";
import PolicyList from "../Pages/Policy";
import AddPolicy from "../Pages/Policy/AddPolicy";
import MapSga from "../Pages/SGA/MapSga";
import SgaList from "../Pages/SGA/SgaList";
import UserManagement from "../Pages/UserManagement";
import UserDetails from "../Pages/UserManagement/UserDetails";

const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <ComingSoon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/samplepage`,
    Component: <SamplePage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/user-management`,
    Component: <UserManagement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/user-management/user-details`,
    Component: <UserDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/new-request`,
    Component: <CourseCreation />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/add-course`,
    Component: <CreateBasicCourseDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-request`,
    Component: <RequestList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-list`,
    Component: <CourseList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/request-details`,
    Component: <RequestDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details`,
    Component: <CourseDetailedView />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/learning-outcome`,
    Component: <LODetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/competancy-statement`,
    Component: <CompetancyStatement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/lesson-plan`,
    Component: <LessonPlan />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/course-structure`,
    Component: <AdditionalInformation />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/assessment`,
    Component: <Assessment />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/question-bank`,
    Component: <QuestionBankPreview />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/course-material`,
    Component: <CourseMaterials />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/bond-details`,
    Component: <TrainerBondDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-learning-outcome`,
    Component: <CreateLO />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-competency-statement`,
    Component: <CreateCompetencyStatement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-webinar-competency-statement`,
    Component: <CreateWebinarCompetativeStatement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/webinar-competency-statement`,
    Component: <WebinarCompetativeStatement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-lesson-plan`,
    Component: <CreateLessonPlan />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-course-structure`,
    Component: <CreateAdditionalInformation />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-assessment`,
    Component: <CreateAssessment />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-material`,
    Component: <CreateMaterials />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data`,
    Component: <CurriculumList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/curriculum-list`,
    Component: <CurriculumList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/policy-list`,
    Component: <PolicyList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-new-curriculum`,
    Component: <AddCurriculum />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/question-bank-list`,
    Component: <QuestionBankList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-question-bank`,
    Component: <AddQuestionBank />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-policy`,
    Component: <AddPolicy />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/skill-gap-analysis`,
    Component: <SgaList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/map-sga`,
    Component: <MapSga />,
  },
  {
    path: `${process.env.PUBLIC_URL}/my-learning-plan`,
    Component: <MyLearningPlan />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/add-assessment`,
    Component: <AddAssessmentCatlog />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/assessment-details`,
    Component: <AssessmentCatlogDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/schedule-assessment`,
    Component: <ScheduleAssessmentsCatlog />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/schedule-details`,
    Component: <ScheduledUserDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/learner-assessments`,
    Component: <LearnerAssessmets />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/self-assessment`,
    Component: <CompetencyAssessment />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/review-self-assessments`,
    Component: <ReviewCompetencyAssessment />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/self-assessment-details`,
    Component: <CompetencyAssessmentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/review-standalone-assessment`,
    Component: <ReviewStandaloneAssessments />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list`,
    Component: <AssessmentList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list/assessment-details`,
    Component: <AssessmentListDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list/assessment-details/attempts`,
    Component: <Attempts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/assessments-list`,
    Component: <AssessmentCatlogList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/scheduled-assessments`,
    Component: <ScheduledAssessments />,
  },
];

export default routes;
