import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H1, H2 } from "../../../AbstractElements";
import { ILT, VILT, WBT } from "../../../Api/constants";
import Btn from "../../../CommonElements/Button";
import CourseSummary from "../../../CommonElements/CourseSummaryCard";
import MapCurriculumModal from "../../../CommonElements/MapCurriculumModal";
import MUIIcons from "../../../CommonElements/MUIIcon/MUIIcons";
import MultiProgressBar from "../../../CommonElements/MultiProgressBar";
import TargetLevelCard from "../../../CommonElements/TargetLevelCard";
import GenericCard from "../../../container/GenericCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getMappedCurriculumList,
  getMappedSgaList,
  getOverallStatistics,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  getDomainList,
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Add,
  Course,
  Curriculum,
  Domain,
  Level,
  OverallStatistics,
  Role,
  Special,
} from "../../../utils/Constant";
import {
  calculateValuePercentage,
  convertMinutesToHours,
  formatCustomDate,
  formatDate,
  ilpCourseSummaryData,
  mapListToOptions,
  multiProgressData,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import "./style.scss";

const ILPOverview = ({ userId }) => {
  const dispatch = useAppDispatch();
  const [isMapCurriculumModalOpen, setIsMapCurriculumModalOpen] =
    useState(false);
  const [domainListData, setDomainListData] = useState([]);
  const [roleListData, setRoleListData] = useState([]);
  const [targetLevelInfoList, setTargetLevelInfoList] = useState([]);
  const { mappedCurriculum, mappedSga } = useAppSelector(
    state => state.learningPlan
  );
  const { domainList, roleList, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { overallStatistics } = useAppSelector(state => state.learningPlan);

  useEffect(() => {
    getCurriculumList();
    dispatch(getRolesList({}));
    dispatch(getDomainList());
    dispatch(getProficiencyList());
    dispatch(getOverallStatistics({ selfEnrollment: false, userId: userId }));
    dispatch(getMappedSgaList({ type: "overview", userId }));
  }, []);

  useEffect(() => {
    const options = mapListToOptions(domainList?.domain, "domain_name", "id");
    setDomainListData(options);
  }, [domainList]);

  useEffect(() => {
    if (roleList) {
      const options = mapListToOptions(roleList.job_role, "name", "id");
      setRoleListData(options);
    }
  }, [roleList]);

  useEffect(() => {
    const targetLevelInfo = mappedSga?.sga?.map(item => {
      const { total_assessment, passed_assessment, level_id, status, sga_id } =
        item;
      const percentageCompleted = calculateValuePercentage(
        passed_assessment,
        total_assessment
      );

      return {
        id: sga_id,
        expertiseLevel: getLevelNameById(level_id),
        totalCount: total_assessment,
        completedCount: passed_assessment,
        percentageCompleted: percentageCompleted,
        status: status,
        completedDate:
          status === "certified" && item.issued_at
            ? formatDate(item.issued_at)
            : "",
      };
    });

    setTargetLevelInfoList(targetLevelInfo);
  }, [mappedSga, proficiencyList]);

  const getLevelNameById = (id: number): string => {
    const level = proficiencyList?.proficiency?.find(level => level.id === id);
    return level ? level.level_name : "";
  };

  const getCurriculumList = () => {
    dispatch(getMappedCurriculumList({ userId: userId }));
  };

  const mapCurriculumModalToggle = isMapped => {
    setIsMapCurriculumModalOpen(!isMapCurriculumModalOpen);
    if (isMapped === true) {
      getCurriculumList();
    }
  };

  const getRoleNameById = id => {
    const role = roleList?.job_role?.find(role => role.id === id);
    return role ? role.name : "-";
  };

  const transformData = () => {
    if (!userId) {
      return {
        targetLevel: "-",
        currentLevel: "-",
        percentageCompleted: "0",
      };
    }
    const currentLevelData = targetLevelInfoList?.find(
      item => item.status === "in_progress"
    );

    return {
      targetLevel: "-",
      currentLevel: currentLevelData?.expertiseLevel,
      percentageCompleted: currentLevelData?.percentageCompleted
        ? currentLevelData?.percentageCompleted
        : "0",
    };
  };

  return (
    <>
      <TargetLevelCard
        cardData={transformData()}
        infoData={userId ? targetLevelInfoList : []}
      />
      <Card className="p-2 mb-3">
        <CardBody>
          <Row className="justify-content-center gap-5 gap-lg-0">
            <Col className="mt-3" lg={4} xs={12}>
              <H2 className="text-black">{OverallStatistics}</H2>
              <H1 className="mt-1 custom-overall-percentage">
                {overallStatistics &&
                  `${calculateValuePercentage(
                    overallStatistics.completed_courses,
                    overallStatistics.total_courses
                  )}%`}
              </H1>
              <div className="mt-2">
                {overallStatistics?.total_courses > 0 && (
                  <MultiProgressBar
                    progressBars={multiProgressData(overallStatistics)}
                  />
                )}
              </div>
            </Col>
            <Col lg={8} xs={12}>
              {overallStatistics && (
                <CourseSummary data={ilpCourseSummaryData(overallStatistics)} />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="p-2 mb-3">
        <CardBody className="custom-overview-curriculum">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <H2 className="text-black">{Curriculum}</H2>
            {userId &&
              hasPermissionToComponent("USER_MANAGEMENT.ADD_CURRICULUM") && (
                <>
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                    onClick={mapCurriculumModalToggle}
                  >
                    {Add}
                  </Btn>
                  {isMapCurriculumModalOpen && (
                    <MapCurriculumModal
                      isOpen={isMapCurriculumModalOpen}
                      toggle={mapCurriculumModalToggle}
                      domains={domainListData}
                      roles={roleListData}
                      userId={userId}
                    />
                  )}
                </>
              )}
          </div>
        </CardBody>
      </Card>

      <Row>
        {mappedCurriculum?.curriculum?.length > 0 &&
          mappedCurriculum?.curriculum?.map(item => {
            const domainValue = mappedCurriculum?.domain[item.id];
            const roleName = getRoleNameById(item.role_id);

            const durationValue = mappedCurriculum?.duration
              ? mappedCurriculum?.duration[item.id]
              : "";
            const combinedIltViltDuration =
              typeof durationValue === "object"
                ? durationValue?.total_ilt_duration +
                  durationValue?.total_vilt_duration
                : 0;

            const totalWbtDuration =
              typeof durationValue === "object"
                ? durationValue?.total_wbt_duration
                : 0;

            return (
              <Col lg={4} md={6} xs={12} key={item.id} className="mb-3">
                <GenericCard
                  header={item.name}
                  footerDate={formatCustomDate(item.published_at)}
                  moduleDetails={[
                    { label: Role, value: roleName, key: `${item.id}_${Role}` },
                    {
                      label: Level,
                      value: item.total_levels.toString(),
                      key: `${item.id}_${Level}`,
                    },
                    {
                      label: Course,
                      value: item.total_courses.toString(),
                      key: `${item.id}_${Course}`,
                    },
                    {
                      label: Domain,
                      value: domainValue
                        ? domainValue?.length?.toString()
                        : "0",
                      key: `${item.id}_${Domain}`,
                    },
                    {
                      label: `${ILT}/${VILT}`,
                      value: `${convertMinutesToHours(combinedIltViltDuration)} h`,
                      key: `${item.id}_${VILT}`,
                    },
                    {
                      label: WBT,
                      value: `${convertMinutesToHours(totalWbtDuration)} h`,
                      key: `${item.id}_${WBT}`,
                    },
                  ]}
                  footerBadge={item?.is_special_curriculum ? Special : ""}
                  isHideMoreOption={true}
                />
              </Col>
            );
          })}
      </Row>
    </>
  );
};

ILPOverview.propTypes = {
  userId: PropTypes.number,
};
export default ILPOverview;
