import React, { useState } from "react";
import { Row, Col, FormGroup, Input } from "reactstrap";

import { Apply, ClearAll, Filter } from "../../utils/Constant";
import DefaultButton from "../DefaultButton";
import FixedFooter from "../FixedFooter";
import H2 from "../Headings/H2Element";
import LabelTooltip from "../LabelTooltip";
import MUIIcons from "../MUIIcon/MUIIcons";
import MultiSelectDropdown from "../MultiSelectDropdown";
import SecondaryButton from "../SecondaryButton";
import SliderModal from "../SliderModal";
import CheckboxButton from "../SwitchButton";

interface DropdownOption {
  name?: string;
  value: string;
  label: string;
}

interface FilterSliderModalProps {
  dropdowns: {
    label: string;
    tooltipText: string;
    options?: DropdownOption[];
    isMultiSelect: boolean;
    key: string;
    defaultSelected?: string[];
    isCheckbox?: boolean;
  }[];
  onDone: (values: { [key: string]: string[] }) => void;
  onClear: (values?: { [key: string]: string[] }) => void;
  defaultFilterValues: {};
  selectedFilterValues: {};
  setSelectedFilterValues: (val) => void;
  setDefaultFilterValues: (val) => void;
  isGrid?: boolean;
}

const FilterSliderModal: React.FC<FilterSliderModalProps> = ({
  dropdowns,
  onDone,
  onClear,
  defaultFilterValues,
  selectedFilterValues,
  setSelectedFilterValues,
  setDefaultFilterValues,
  isGrid = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [defaultFilterValuesCopy, setDefaultFilterValuesCopy] = useState({});
  const [selectedFilterValuesCopy, setSelectedFilterValuesCopy] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  const handleSelectChange = (key: string, values: string[]) => {
    setSelectedFilterValues(prev => ({ ...prev, [key]: values }));
    const dropdownItem = dropdowns.find(item => item.key === key);
    if (dropdownItem) {
      const defaultValues = dropdownItem.options
        .filter(option => values.includes(option.name))
        .map(option => ({
          label: option.label,
          value: option.value,
          name: option.name,
        }));

      setDefaultFilterValues(prev => ({
        ...prev,
        [key]: defaultValues,
      }));
    }
  };

  const handleDone = () => {
    setDefaultFilterValuesCopy(defaultFilterValues);
    setSelectedFilterValuesCopy(selectedFilterValues);
    onDone(selectedFilterValues);
    toggle();
  };

  const handleClear = () => {
    setDefaultFilterValuesCopy({});
    setSelectedFilterValuesCopy({});
    setSelectedFilterValues({});
    onClear(selectedFilterValues);
  };

  const renderDropdownInput = dropdown => {
    if (dropdown.isCheckbox) {
      return (
        <div className="d-flex gap-2 mt-3">
          <p className="fw-bold">
            <LabelTooltip
              label={dropdown.label}
              tooltipText={dropdown.tooltipText}
              important={false}
              placement={"bottom"}
            />
          </p>
          <div className="mt-1">
            <CheckboxButton
              onChange={e => {
                setSelectedFilterValues(prev => ({
                  ...prev,
                  [dropdown.key]: e.target.checked,
                }));
              }}
              checked={selectedFilterValues[dropdown.key]}
            />
          </div>
        </div>
      );
    } else if (dropdown.isMultiSelect) {
      return (
        <MultiSelectDropdown
          onChange={values => handleSelectChange(dropdown.key, values)}
          options={dropdown.options}
          placeholder={dropdown.tooltipText}
          defaultSelected={defaultFilterValues[dropdown.key] || []}
        />
      );
    } else {
      return (
        <Input
          type="select"
          onChange={e => handleSelectChange(dropdown.key, [e.target.value])}
          value={selectedFilterValues[dropdown.key]?.[0] || ""}
        >
          {dropdown.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      );
    }
  };

  return (
    <>
      <MUIIcons
        className="pointer primary-icon-color"
        onClick={toggle}
        iconName="FilterAltOutlined"
        size={24}
      />
      <SliderModal
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setDefaultFilterValues(defaultFilterValuesCopy);
          setSelectedFilterValues(selectedFilterValuesCopy);
        }}
      >
        <H2>{Filter}</H2>
        <div className="modal-body">
          <Row className="mt-3">
            {dropdowns.map((dropdown: any) => (
              <Col key={dropdown.key} lg={isGrid ? 6 : 12}>
                <FormGroup>
                  {!dropdown.isCheckbox && (
                    <LabelTooltip
                      label={dropdown.label}
                      tooltipText={dropdown.tooltipText}
                      important={false}
                    />
                  )}
                  {renderDropdownInput(dropdown)}
                </FormGroup>
              </Col>
            ))}
          </Row>
        </div>
        <div>
          <FixedFooter>
            <SecondaryButton onClick={handleClear}>{ClearAll}</SecondaryButton>
            <DefaultButton color="primary" onClick={handleDone}>
              {Apply}
            </DefaultButton>
          </FixedFooter>
        </div>
      </SliderModal>
    </>
  );
};

export default FilterSliderModal;
