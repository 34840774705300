import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import {
  DefaultButton,
  H4,
  SecondaryButton,
} from "../../../../AbstractElements";
import {
  MINUTES_PER_DAY,
  PUBLISHED,
  STATUS_200,
} from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../CommonElements/MultiSelectDropdown";
import CheckboxButton from "../../../../CommonElements/SwitchButton";
import TagInput from "../../../../CommonElements/TagInput";
import Module from "../../../../container/Module";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  createAssessmentCatlog,
  getAssessmentCatlogById,
  updateAssessmentCatlog,
} from "../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { getReAttemptPolicyList } from "../../../../ReduxToolkit/Reducers/PolicySilce";
import {
  getAssessmentModuleByQbId,
  getAssessmentQuestionBankList,
} from "../../../../ReduxToolkit/Reducers/QuestionBankSlice";
import {
  AddAssessment,
  AssessmentDurationInMinutes,
  AssessmentDurationToolTipMessage,
  AssessmentName,
  AssessmentNameToolTipMessage,
  AssessmentTagToolTipMessage,
  AssessmentType,
  AssessmentTypeValidationMessage,
  DoYouWantToSetThisAsRecurringAssessment,
  EndAfter,
  Enter,
  EstimatedTimeValidationMessage,
  FrequencyCountValidationMessage,
  isRecurringToolTipMessage,
  MapModuleToolTipMessage,
  Modify,
  ModifyAssessment,
  Modules,
  ModuleWeightageError,
  ModuleWeightageErrorMessage,
  NameValidationMessage,
  Occurrences,
  OccurrenceValidationMessage,
  OverallCount,
  OverallCountError,
  OverallQuestionCountToolTipMessage,
  OverallQuestionCountValidationMessage,
  PleaseMapModule,
  PleaseProvideRequiredInformation,
  PleaseSelectQuestionBank,
  Policy,
  PolicyIdValidationMessage,
  Publish,
  QuestionBank,
  ReAttemptPolicy,
  RecurEvery,
  SaveAsDraft,
  Select,
  SelectAssessmentTypeToolTipMessage,
  SelectModules,
  SelectPolicyToolTipMessage,
  SelectQuestionBankField,
  SelectQuestionBankToolTipMessage,
  ShowWronglyAnsweredQuestion,
  ShowWronglyAnsweredToolTipMessage,
  Tag,
  TermAndCondition,
  TermAndConditionValidationMessage,
  TermsAndConditionToolTipMessage,
} from "../../../../utils/Constant";
import {
  assessmentCreationData,
  assessmentTypes,
  frequencyTypes,
  handleKeyDownOnNumericWithoutDecimal,
  richTextSupportedItems,
  scrollToTop,
  showToast,
  validateValue,
} from "../../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../../utils/helper/permission";

interface FormValues {
  name: string;
  assessment_type: string;
  question_bank_id: string;
  modules: string[];
  overall_question_count: string;
  estimated_time: string;
  policy_id: string;
  frequency_type: string;
  frequency_count: string;
  occurrence: string;
  term_and_condition: string;
  [key: string]: any;
}

interface FormErrors {
  [key: string]: string;
}

const initialFormValues: FormValues = {
  name: "",
  assessment_type: "standalone",
  question_bank_id: "",
  modules: [],
  overall_question_count: null,
  estimated_time: null,
  policy_id: null,
  frequency_type: "weekly",
  frequency_count: null,
  occurrence: null,
  term_and_condition: "",
  tag: null,
};

const initialFormErrors: FormErrors = {};

const AddAssessmentCatalog: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentId } = location.state || "";
  const [basicInputFormValue, setBasicInputFormValue] =
    useState<FormValues>(initialFormValues);
  const [errors, setErrors] = useState<FormErrors>(initialFormErrors);
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [questionBankListData, setQuestionBankListData] = useState([]);
  const [policyListData, setPolicyListData] = useState([]);
  const [terms, setTerms] = useState("");
  const [isShowWronglyAnswered, setIsShowWronglyAnswered] =
    useState<boolean>(false);
  const { questionBankList, assessmentModule } = useAppSelector(
    state => state.questionBank
  );
  const { assessmentCatlogDetailData } = useAppSelector(
    state => state.assessmentCatlog
  );
  const { policyList } = useAppSelector(state => state.policy);
  const [preSelectedModules, setPreSelectedModules] = useState([]);
  const [modulesData, setModulesData] = useState([]);
  const [overallCount, setOverallCount] = useState(0);

  useEffect(() => {
    setValuesToInitials();
    dispatch(
      getAssessmentQuestionBankList({
        status: PUBLISHED,
        tag: null,
        self_assessment: null,
      })
    );
    dispatch(getReAttemptPolicyList({}));
  }, [dispatch]);

  useEffect(() => {
    if (assessmentCatlogDetailData && modulesData) {
      updateData();
    } else {
      setValuesToInitials();
    }
  }, [assessmentCatlogDetailData]);

  const updateData = async () => {
    let module = null;
    if (assessmentCatlogDetailData?.question_bank_id) {
      module = await dispatch(
        getAssessmentModuleByQbId({
          question_bank_id: assessmentCatlogDetailData?.question_bank_id,
        })
      );

      if (module?.payload?.modules) {
        const data: any = {
          name: assessmentCatlogDetailData?.name,
          assessment_type: assessmentCatlogDetailData?.assessment_type,
          question_bank_id: assessmentCatlogDetailData?.question_bank_id,
          modules: [],
          overall_question_count:
            assessmentCatlogDetailData?.overall_question_count,
          estimated_time: assessmentCatlogDetailData?.estimated_time,
          policy_id: assessmentCatlogDetailData?.policy_id,
          frequency_type: assessmentCatlogDetailData?.frequency_type,
          frequency_count: assessmentCatlogDetailData?.frequency_count,
          occurrence: assessmentCatlogDetailData?.occurrence,
          term_and_condition:
            assessmentCatlogDetailData?.term_and_condition || "",
          tag: assessmentCatlogDetailData?.tags || null,
        };
        setTerms(assessmentCatlogDetailData?.term_and_condition || "");
        setIsRecurring(assessmentCatlogDetailData?.recertification);
        setIsShowWronglyAnswered(
          assessmentCatlogDetailData?.is_show_wrong_answer
        );

        const selected = [];
        const weights = {};
        assessmentCatlogDetailData?.modules?.forEach((item: any) => {
          selected.push(item?.module_id);
          weights[item?.module_id] = item?.weightage;
        });

        const updatedModuleData = [];
        module?.payload?.modules?.forEach(item => {
          const userObj = {
            name: item.id,
            label: item.module_name,
            value: item.module_name,
          };
          updatedModuleData.push(userObj);
        });
        setModulesData(updatedModuleData);

        getMultiDropdownModulesData(
          selected,
          data,
          updatedModuleData,
          module?.payload?.modules,
          weights
        );
      }
    }
  };

  const setValuesToInitials = () => {
    setModulesData([]);
    setPreSelectedModules([]);
    setIsShowWronglyAnswered(false);
    setPolicyListData([]);
    setQuestionBankListData([]);
    setIsRecurring(false);
    setErrors(initialFormErrors);
    setBasicInputFormValue(initialFormValues);
  };

  useEffect(() => {
    if (assessmentId && assessmentId !== null) {
      dispatch(getAssessmentCatlogById({ id: assessmentId }));
    }
  }, [assessmentId]);

  useEffect(() => {
    const modules = [];
    assessmentModule?.modules?.forEach(item => {
      const userObj = {
        name: item.id,
        label: item.module_name,
        value: item.module_name,
      };
      modules.push(userObj);
    });
    setModulesData(modules);
  }, [assessmentModule]);

  useEffect(() => {
    const banks = [];
    banks.push({ label: Select + " " + QuestionBank, value: "" });
    if (questionBankList) {
      questionBankList?.question_banks?.forEach((item: any) => {
        if (
          assessmentCreationData.includes(
            basicInputFormValue.assessment_type
          ) &&
          !item?.is_competency
        )
          banks.push({ label: item?.name, value: item?.id });
        if (
          !assessmentCreationData.includes(
            basicInputFormValue.assessment_type
          ) &&
          item?.is_competency
        )
          banks.push({ label: item?.name, value: item?.id });
      });
    }
    setQuestionBankListData(banks);
  }, [questionBankList, basicInputFormValue.assessment_type]);

  useEffect(() => {
    const policy = [];
    policy.push({ label: Select + " " + Policy, value: "" });
    if (policyList) {
      policyList?.policy?.forEach(item => {
        policy.push({ label: item?.name, value: item?.id });
      });
    }
    setPolicyListData(policy);
  }, [policyList]);

  type ValidationResponse = { isValid: boolean };

  const validateField = (
    name: string,
    value: string,
    isRequired = false,
    isNumeric = false,
    minValue?: number,
    maxValue?: number
  ): string | null => {
    const validationResponse: ValidationResponse = validateValue(
      name,
      value,
      isRequired,
      isNumeric,
      minValue,
      maxValue
    );

    return validationResponse.isValid ? null : getValidationMessage(name);
  };

  const getValidationMessage = (name: string): string => {
    switch (name) {
      case "name":
        return NameValidationMessage;
      case "assessment_type":
        return AssessmentTypeValidationMessage;
      case "overall_question_count":
        return OverallQuestionCountValidationMessage;
      case "estimated_time":
        return EstimatedTimeValidationMessage;
      case "policy_id":
        return PolicyIdValidationMessage;
      case "frequency_count":
        return FrequencyCountValidationMessage;
      case "occurrence":
        return OccurrenceValidationMessage;
      default:
        return "";
    }
  };

  const validateInput = (name: string, value: string): string | null => {
    const fieldValidations: {
      [key: string]: [boolean, boolean, number?, number?];
    } = {
      name: [true, false],
      assessment_type: [false, false],
      overall_question_count: [false, true, 1, 200],
      estimated_time: [false, true, 1, MINUTES_PER_DAY],
      policy_id: [false, false],
      frequency_count: isRecurring ? [false, true, 1, 10] : undefined,
      occurrence: isRecurring ? [false, true, 1, 10] : undefined,
    };

    const validationParams = fieldValidations[name];
    if (validationParams) {
      return validateField(name, value, ...validationParams);
    }

    return null;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "assessment_type") {
      setPreSelectedModules([]);
      setBasicInputFormValue({
        name: basicInputFormValue?.name || "",
        assessment_type: "standalone",
        question_bank_id: "",
        modules: [],
        overall_question_count: null,
        estimated_time: null,
        policy_id: null,
        frequency_type: "weekly",
        frequency_count: null,
        occurrence: null,
        term_and_condition: "",
        tag: null,
      });
    }

    const error = validateInput(name, value);
    if (!error || value === "")
      setBasicInputFormValue(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: error }));
  };

  const handleWeightageChange = (
    moduleId: number,
    newWeightage: number | ""
  ) => {
    setBasicInputFormValue(prevFormValue => {
      const updatedModules = prevFormValue.modules.map((module: any) =>
        module.module_id === moduleId
          ? { ...module, weightage: newWeightage }
          : module
      );

      return { ...prevFormValue, modules: updatedModules };
    });
  };

  const validateAllFields = (type = null): boolean => {
    const newErrors: FormErrors = {};
    let isValid = true;
    Object.keys(basicInputFormValue).forEach(key => {
      if (
        assessmentCreationData.includes(type) ||
        (key !== "overall_question_count" &&
          key !== "policy_id" &&
          key !== "estimated_time" &&
          (!isRecurring || (key !== "frequency_count" && key !== "occurrence")))
      ) {
        const value = basicInputFormValue[key as keyof FormValues];
        const error = validateInput(key, value);

        if (error) {
          newErrors[key] = error;
          isValid = false;
        }
      }
    });

    if (
      assessmentCreationData.includes(basicInputFormValue.assessment_type) &&
      basicInputFormValue?.modules?.length === 0
    ) {
      newErrors["modules"] = PleaseMapModule;
      isValid = false;
    }

    if (terms === "") {
      newErrors["term_and_condition"] = TermAndConditionValidationMessage;
      isValid = false;
    }

    if (!basicInputFormValue?.question_bank_id) {
      newErrors["question_bank_id"] = PleaseSelectQuestionBank;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const onSubmit = async status => {
    const data = { ...basicInputFormValue };

    const validationResponse = validateValue(
      "name",
      basicInputFormValue?.name,
      true,
      false
    );
    if (!validationResponse.isValid) {
      showToast(NameValidationMessage, "error");
      setErrors({ ...errors, ["name"]: NameValidationMessage });
      scrollToTop();
      return;
    }

    const validated = validateAllFields(data?.assessment_type);
    if (!validated) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
      return;
    }

    if (
      assessmentCreationData.includes(basicInputFormValue.assessment_type) &&
      parseInt(data?.overall_question_count) > overallCount
    ) {
      showToast(OverallCountError + overallCount, "error");
      return;
    }

    let totalPercent = 0;
    let isValidValue = true;
    data?.modules?.forEach((item: any) => {
      if (
        (item?.weightage || item?.weightage === 0) &&
        item?.weightage !== ""
      ) {
        totalPercent += item?.weightage;
      } else {
        isValidValue = false;
        showToast(ModuleWeightageErrorMessage(item?.module_name), "error");
        return;
      }
    });

    if (!isValidValue) {
      return;
    }

    if (
      assessmentCreationData.includes(basicInputFormValue.assessment_type) &&
      totalPercent !== 100
    ) {
      showToast(ModuleWeightageError, "error");
      return;
    }

    if (status === "draft" || validated) {
      data.recertification = isRecurring?.toString();
      data.is_show_wrong_answer = isShowWronglyAnswered?.toString();
      data.status = status;
      data.term_and_condition = terms;

      let res = null;
      if (!assessmentId) {
        res = await dispatch(createAssessmentCatlog({ data: data }));
      } else {
        data.id = assessmentId;
        res = await dispatch(updateAssessmentCatlog({ data: data }));
      }

      if (
        res?.payload?.data?.status_code === STATUS_200 ||
        res?.payload?.data?.id
      ) {
        setValuesToInitials();
        return navigate(
          `${process.env.PUBLIC_URL}/assessments/assessments-list`
        );
      }
    }
  };

  const onQuestionBankChange = async e => {
    const value = e.target.value;
    setPreSelectedModules([]);
    setModulesData([]);
    setBasicInputFormValue(prev => ({
      ...prev,
      ["question_bank_id"]: value,
    }));
    if (value) {
      const data = await dispatch(
        getAssessmentModuleByQbId({ question_bank_id: parseInt(value) })
      );
      const modules = [];
      const moduleWeightage = [];
      if (
        !assessmentCreationData.includes(basicInputFormValue.assessment_type) &&
        data?.payload?.modules
      ) {
        data?.payload?.modules?.map(item => {
          moduleWeightage.push({
            module_id: item?.id,
            module_name: item?.module_name,
            question_count: item?.question_count,
            weightage: 100,
          });
          modules.push({
            name: item.id,
            label: item.module_name,
            value: item.module_name,
          });
        });
      }
      setBasicInputFormValue(prev => ({
        ...prev,
        ["modules"]: moduleWeightage,
      }));
      setPreSelectedModules(modules);
    }
  };

  const getMultiDropdownModulesData = (
    value: any[],
    data = null,
    updatedModuleData = null,
    updatedAssessmentModule = null,
    weights = null
  ) => {
    const modulesMap = [];
    const ids = [];
    let questions = 0;

    if (updatedModuleData === null)
      modulesData?.forEach((element: any) => {
        if (value.includes(element.name)) {
          ids.push(element.name);
          modulesMap.push({
            name: element.name,
            value: element.label,
            label: element.label,
          });
        }
      });
    else {
      updatedModuleData?.forEach((element: any) => {
        if (value.includes(element.name)) {
          ids.push(element.name);
          modulesMap.push({
            name: element.name,
            value: element.label,
            label: element.label,
          });
        }
      });
    }
    if (data === null)
      setBasicInputFormValue({ ...basicInputFormValue, ["modules"]: [] });
    else setBasicInputFormValue({ ...data, ["modules"]: [] });

    const modules = [];

    if (ids) {
      if (updatedAssessmentModule === null)
        assessmentModule?.modules?.forEach(item => {
          if (ids?.includes(item?.id)) {
            questions += item?.question_count;
            modules.push({
              module_name: item?.module_name,
              question_count: item?.question_count,
              module_id: item?.id,
              weightage: 0,
            });
          }
        });
      else
        updatedAssessmentModule?.forEach(item => {
          if (ids?.includes(item?.id)) {
            questions += item?.question_count;
            modules.push({
              module_name: item?.module_name,
              question_count: item?.question_count,
              module_id: item?.id,
              weightage: weights[item?.id],
            });
          }
        });
    }

    if (data === null)
      setBasicInputFormValue({ ...basicInputFormValue, ["modules"]: modules });
    else setBasicInputFormValue({ ...data, ["modules"]: modules });
    setOverallCount(questions);
    setPreSelectedModules(modulesMap);
  };

  const handleTagsChange = (newTags: string[]) => {
    setBasicInputFormValue(prev => ({
      ...prev,
      ["tag"]: newTags,
    }));
    return newTags;
  };

  return (
    <div className="page-body mb-5 pb-5">
      <Card>
        <CardHeader>
          <H4>{assessmentId ? ModifyAssessment : AddAssessment}</H4>
        </CardHeader>
        <CardBody>
          <Row className="mt-4">
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={AssessmentName}
                  tooltipText={AssessmentNameToolTipMessage}
                  important
                />
                <Input
                  onChange={handleInputChange}
                  value={basicInputFormValue.name}
                  name="name"
                  type="text"
                  placeholder={`${Enter} ${AssessmentName}`}
                  maxLength={100}
                  invalid={!!errors.name}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={AssessmentType}
                  tooltipText={SelectAssessmentTypeToolTipMessage}
                  important
                />
                <Input
                  onChange={handleInputChange}
                  value={basicInputFormValue.assessment_type}
                  name="assessment_type"
                  type="select"
                  invalid={!!errors.assessment_type}
                >
                  {assessmentTypes.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors.assessment_type && (
                  <div className="invalid-feedback">
                    {errors.assessment_type}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Divider />
          <Row className="mt-4">
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={SelectQuestionBankField}
                  tooltipText={SelectQuestionBankToolTipMessage}
                  important
                />
                <Input
                  onChange={onQuestionBankChange}
                  value={basicInputFormValue.question_bank_id}
                  name="question_bank_id"
                  type="select"
                  invalid={!!errors.question_bank_id}
                >
                  {questionBankListData?.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors.question_bank_id && (
                  <div className="invalid-feedback">
                    {errors.question_bank_id}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={SelectModules}
                  tooltipText={MapModuleToolTipMessage}
                  important
                />
                <MultiSelectDropdown
                  onChange={values => getMultiDropdownModulesData(values)}
                  options={modulesData}
                  placeholder={`${Select} ${Modules}`}
                  defaultSelected={preSelectedModules}
                />
                {errors.modules && (
                  <div className="mt-1 text-danger">{errors.modules}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          {assessmentCreationData.includes(
            basicInputFormValue.assessment_type
          ) &&
            basicInputFormValue?.modules?.map((item: any) => {
              return (
                <div className="mt-3" key={item.module_id}>
                  <Module
                    key={item?.module_id}
                    moduleId={item?.module_id}
                    moduleTitle={item?.module_name}
                    questionsCount={item?.question_count}
                    weightage={item?.weightage}
                    onWeightageChange={(_, newWeightage: any) => {
                      handleWeightageChange(item?.module_id, newWeightage);
                    }}
                    disabled={item?.question_count === 0}
                  />
                </div>
              );
            })}

          {assessmentCreationData.includes(
            basicInputFormValue.assessment_type
          ) && (
            <Row className="mt-4">
              <Col sm="12" lg="3">
                <FormGroup>
                  <LabelTooltip
                    label={OverallCount}
                    tooltipText={OverallQuestionCountToolTipMessage}
                    important={false}
                  />
                  <Input
                    onChange={handleInputChange}
                    value={basicInputFormValue.overall_question_count}
                    name="overall_question_count"
                    onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                    type="number"
                    placeholder={`${Enter} ${OverallCount}`}
                    invalid={!!errors.overall_question_count}
                  />
                  {errors.overall_question_count && (
                    <div className="invalid-feedback">
                      {errors.overall_question_count}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12" lg="3">
                <FormGroup>
                  <LabelTooltip
                    label={AssessmentDurationInMinutes}
                    tooltipText={AssessmentDurationToolTipMessage}
                    important
                  />
                  <Input
                    onChange={handleInputChange}
                    value={basicInputFormValue.estimated_time}
                    name="estimated_time"
                    type="number"
                    onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                    placeholder={`${Enter} ${AssessmentDurationInMinutes}`}
                    maxLength={3}
                    invalid={!!errors.estimated_time}
                  />
                  {errors.estimated_time && (
                    <div className="invalid-feedback">
                      {errors.estimated_time}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={ReAttemptPolicy}
                    tooltipText={SelectPolicyToolTipMessage}
                    important
                  />
                  <Input
                    onChange={handleInputChange}
                    value={basicInputFormValue.policy_id}
                    name="policy_id"
                    type="select"
                    invalid={!!errors.policy_id}
                  >
                    {policyListData?.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                  {errors.policy_id && (
                    <div className="invalid-feedback">{errors.policy_id}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}
          {assessmentCreationData.includes(
            basicInputFormValue.assessment_type
          ) && (
            <Row>
              <Col sm="12" lg="6" className="d-flex gap-2 mt-3">
                <p className="fw-bold">
                  <LabelTooltip
                    label={ShowWronglyAnsweredQuestion}
                    tooltipText={ShowWronglyAnsweredToolTipMessage}
                    important={false}
                  />
                </p>
                <div className="mt-1">
                  <CheckboxButton
                    onChange={e => {
                      setIsShowWronglyAnswered(e.target.checked);
                    }}
                    checked={isShowWronglyAnswered}
                  />
                </div>
              </Col>
              <Col sm="12" lg="6" className="d-flex gap-2 mt-3">
                <p className="fw-bold">
                  <LabelTooltip
                    label={DoYouWantToSetThisAsRecurringAssessment}
                    tooltipText={isRecurringToolTipMessage}
                    important={false}
                  />
                </p>
                <div className="mt-1">
                  <CheckboxButton
                    onChange={e => setIsRecurring(e.target.checked)}
                    checked={isRecurring}
                  />
                </div>
              </Col>
            </Row>
          )}

          {assessmentCreationData.includes(
            basicInputFormValue.assessment_type
          ) &&
            isRecurring && (
              <Row className="mt-1">
                <Col sm="12" lg="8">
                  <LabelTooltip
                    label={RecurEvery}
                    tooltipText={`${Enter} ${Occurrences}`}
                    important
                  />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          onChange={handleInputChange}
                          value={basicInputFormValue.frequency_count}
                          name="frequency_count"
                          type="number"
                          onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                          placeholder={`${Enter} ${Occurrences}`}
                          invalid={!!errors.frequency_count}
                        />
                        {errors.frequency_count && (
                          <div className="invalid-feedback">
                            {errors.frequency_count}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          onChange={handleInputChange}
                          value={basicInputFormValue.frequency_type}
                          name="frequency_type"
                          type="select"
                          invalid={!!errors.frequency_type}
                        >
                          {frequencyTypes.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Input>
                        {errors.frequency_type && (
                          <div className="invalid-feedback">
                            {errors.frequency_type}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>

                <Col sm="12" lg="4">
                  <FormGroup>
                    <LabelTooltip
                      label={EndAfter}
                      tooltipText={`${Enter} ${EndAfter + " " + Occurrences}`}
                      important
                    />
                    <Input
                      onChange={handleInputChange}
                      value={basicInputFormValue.occurrence}
                      name="occurrence"
                      type="number"
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      placeholder={`${Enter} ${EndAfter + " " + Occurrences}`}
                      invalid={!!errors.occurrence}
                    />
                    {errors.occurrence && (
                      <div className="invalid-feedback">
                        {errors.occurrence}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            )}

          <Row>
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={Tag}
                  tooltipText={AssessmentTagToolTipMessage}
                  important={false}
                />
                <TagInput
                  initialTags={basicInputFormValue?.tag || []}
                  onTagsChange={handleTagsChange}
                />
                {errors.tag && (
                  <div className="mt-1 text-danger">{errors.tag}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm="12" lg="12">
              <FormGroup>
                <LabelTooltip
                  label={TermAndCondition}
                  tooltipText={TermsAndConditionToolTipMessage}
                  important
                />
                <div
                  className={
                    errors.other_prerequisites ? "border border-danger" : ""
                  }
                >
                  <CKEditor
                    onChange={(_event, editor) => {
                      const data = editor.getData();
                      setTerms(data);
                    }}
                    config={{
                      toolbar: {
                        items: richTextSupportedItems,
                      },
                    }}
                    editor={ClassicEditor}
                    data={terms}
                  />
                </div>
                <div className="mt-1 text-sm text-danger">
                  {errors.term_and_condition}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {hasPermissionToComponent("CREATE_ASSESSMENT_CATLOG") && (
        <div>
          <FixedFooter>
            <SecondaryButton onClick={() => onSubmit("draft")}>
              {SaveAsDraft}
            </SecondaryButton>
            <DefaultButton
              color="primary"
              onClick={() => onSubmit("published")}
            >
              {assessmentId && assessmentCatlogDetailData?.status !== "draft"
                ? Modify
                : Publish}
            </DefaultButton>
          </FixedFooter>
        </div>
      )}
    </div>
  );
};

export default AddAssessmentCatalog;
