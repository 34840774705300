import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  ASSESSMENTS_TO_BE_REVIEWED,
  ASSESSMENTS_TO_BE_REVIEWED_URL,
  BULK_UPLOAD_USERS,
  BULK_UPLOAD_USERS_URL,
  CREATE_ASSESSMENT_CATLOG,
  CREATE_ASSESSMENT_CATLOG_URL,
  DELETE,
  DELETE_SCHEDULED,
  DELETE_SCHEDULED_ASSESSMENT,
  DELETE_SCHEDULED_ASSESSMENT_URL,
  DELETE_SCHEDULED_URL,
  EDIT_SCHEDULE_ASSESSMENT_CATLOG,
  END_LEARNER_ASSESSMENT,
  END_LEARNER_ASSESSMENT_URL,
  GET,
  GET_ASSESSEMENT_RESULT_FOR_REVIEW,
  GET_ASSESSMENT_CATLOG_BY_ID,
  GET_ASSESSMENT_CATLOG_BY_ID_URL,
  GET_ASSESSMENT_CATLOG_LIST,
  GET_ASSESSMENT_CATLOG_LIST_URL,
  GET_ASSESSMENT_RESULT_FOR_REVIEW_URL,
  GET_ASSIGNED_ASSESSMENT_TO_LEARNER,
  GET_ASSIGNED_ASSESSMENT_TO_LEARNER_URL,
  GET_COMPETENCY_SCHEDULED_DETAILS_BY_SCHEDULE_ID_URL,
  GET_LEARNER_AND_RM_RESPONSE,
  GET_LEARNER_AND_RM_RESPONSE_URL,
  GET_SCHEDULED_BY_ASSESSMENT_ID,
  GET_SCHEDULED_BY_ASSESSMENT_ID_URL,
  GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID,
  GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID_URL,
  POST,
  PUT,
  REQUEST_FOR_MODIFICATION,
  REQUEST_FOR_MODIFICATION_URL,
  REVIEW_LEARNER_ASSESSMENT,
  REVIEW_LEARNER_ASSESSMENT_URL,
  REVIEW_MODIFICATION_REQUEST,
  REVIEW_MODIFICATION_REQUEST_URL,
  SCHEDULE_ASSESSMENT_CATLOG,
  SCHEDULE_ASSESSMENT_CATLOG_URL,
  START_LEARNER_ASSESSMENT,
  START_LEARNER_ASSESSMENT_URL,
  UPDATE_ASSESSMENT_CATLOG,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import { FillAllFields, SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface AssessmentCatlogInterface {
  numberLevel: number;
  assessmentCatlogDetailData: AssessmentCatlog;
  assessmentCatlogList: AssessmentCatlogListData;
  scheduledAssessmentsById: any;
  scheduledDetails: any;
  assessmentsToBeReviewed: any;
  learnerAssesmentQuestions: any;
  learnerAssignedAssessments: any;
  assessmentResultForReview: any;
  loading: boolean;
  error: string;
  showFinish: boolean;
  learnerAndRMResponse: any;
}

const initialState: AssessmentCatlogInterface = {
  numberLevel: 1,
  assessmentCatlogList: { assessments: [] },
  scheduledAssessmentsById: { schedules: [] },
  learnerAssesmentQuestions: {},
  scheduledDetails: {},
  learnerAssignedAssessments: [],
  assessmentsToBeReviewed: { assessments: [] },
  assessmentResultForReview: {},
  assessmentCatlogDetailData: {
    id: null,
    question_bank_id: null,
    overall_question_count: null,
    estimated_time: null,
    module_count: null,
    assessment_type: "standalone",
    status: "draft",
    created_at: "",
    created_by: null,
    updated_by: null,
    tag: [],
    name: "",
    recertification: false,
    is_show_wrong_answer: false,
    requests: {
      modification: null,
      extension: null,
    },
  },
  loading: false,
  error: null,
  showFinish: false,
  learnerAndRMResponse: {},
};

export const bulkUploadUsers = createAsyncThunk(
  BULK_UPLOAD_USERS,
  async ({ file }: { file: any }) => {
    const response = await apiRequest(POST, BULK_UPLOAD_USERS_URL(), file);
    return response.data;
  }
);

export const getAssessmentCatlogList = createAsyncThunk(
  GET_ASSESSMENT_CATLOG_LIST,
  async ({
    status,
    assessment_type,
    tag,
  }: {
    status: string;
    assessment_type: any;
    tag: any;
  }) => {
    const response = await apiRequest(
      GET,
      GET_ASSESSMENT_CATLOG_LIST_URL(status, assessment_type, tag)
    );
    return response.data;
  }
);

export const getAssessmentCatlogById = createAsyncThunk(
  GET_ASSESSMENT_CATLOG_BY_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, GET_ASSESSMENT_CATLOG_BY_ID_URL(id));
    return response.data;
  }
);

export const createAssessmentCatlog = createAsyncThunk(
  CREATE_ASSESSMENT_CATLOG,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      POST,
      CREATE_ASSESSMENT_CATLOG_URL(),
      data
    );
    return response;
  }
);

export const requestForModificationAcceessAPI = createAsyncThunk(
  REQUEST_FOR_MODIFICATION,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      POST,
      REQUEST_FOR_MODIFICATION_URL(),
      data
    );
    return response;
  }
);

export const updateAssessmentCatlog = createAsyncThunk(
  UPDATE_ASSESSMENT_CATLOG,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      PUT,
      CREATE_ASSESSMENT_CATLOG_URL(),
      data
    );
    return response;
  }
);

export const scheduleAssessmentCatlog = createAsyncThunk(
  SCHEDULE_ASSESSMENT_CATLOG,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      POST,
      SCHEDULE_ASSESSMENT_CATLOG_URL(),
      data
    );
    return response;
  }
);

export const editScheduledAssessmentCatlog = createAsyncThunk(
  EDIT_SCHEDULE_ASSESSMENT_CATLOG,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      PUT,
      SCHEDULE_ASSESSMENT_CATLOG_URL(),
      data
    );
    return response;
  }
);

export const getScheduledByAssessmentId = createAsyncThunk(
  GET_SCHEDULED_BY_ASSESSMENT_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(
      GET,
      GET_SCHEDULED_BY_ASSESSMENT_ID_URL(id)
    );
    return response.data;
  }
);

export const reviewModificationRequest = createAsyncThunk(
  REVIEW_MODIFICATION_REQUEST,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      PUT,
      REVIEW_MODIFICATION_REQUEST_URL(),
      data
    );
    return response;
  }
);

export const deleteScheduled = createAsyncThunk(
  DELETE_SCHEDULED,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(DELETE, DELETE_SCHEDULED_URL(), data);
    return response;
  }
);

export const getScheduledDetailsByScheduleId = createAsyncThunk(
  GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID,
  async ({ id, assessment_id }: { id: number; assessment_id: number }) => {
    let url =
      GET_COMPETENCY_SCHEDULED_DETAILS_BY_SCHEDULE_ID_URL(assessment_id);
    if (id && id !== null) {
      url = GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID_URL(id, assessment_id);
    }
    const response = await apiRequest(GET, url);
    return response.data;
  }
);

export const getAssinedAssessmentToLearner = createAsyncThunk(
  GET_ASSIGNED_ASSESSMENT_TO_LEARNER,
  async () => {
    const response = await apiRequest(
      GET,
      GET_ASSIGNED_ASSESSMENT_TO_LEARNER_URL()
    );
    return response.data;
  }
);

export const startLearnerAssessment = createAsyncThunk(
  START_LEARNER_ASSESSMENT,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, START_LEARNER_ASSESSMENT_URL(id));
    return response.data;
  }
);

export const endLearnerAssessment = createAsyncThunk(
  END_LEARNER_ASSESSMENT,
  async ({ data }: { data }) => {
    const response = await apiRequest(POST, END_LEARNER_ASSESSMENT_URL(), data);
    return response.data;
  }
);

export const getAssessmentsToBeReviewed = createAsyncThunk(
  ASSESSMENTS_TO_BE_REVIEWED,
  async ({
    assessment_type,
    tag,
  }: {
    assessment_type?: string[];
    tag?: string;
  }) => {
    const response = await apiRequest(
      GET,
      ASSESSMENTS_TO_BE_REVIEWED_URL(assessment_type, tag)
    );
    return response.data;
  }
);

export const getAssessmentResultForReview = createAsyncThunk(
  GET_ASSESSEMENT_RESULT_FOR_REVIEW,
  async ({ participant_id }: { participant_id: number }) => {
    const response = await apiRequest(
      GET,
      GET_ASSESSMENT_RESULT_FOR_REVIEW_URL(participant_id)
    );
    return response;
  }
);

export const reviewLearnerAssessment = createAsyncThunk(
  REVIEW_LEARNER_ASSESSMENT,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      PUT,
      REVIEW_LEARNER_ASSESSMENT_URL(),
      data
    );
    return response;
  }
);

export const deleteScheduledAssessment = createAsyncThunk(
  DELETE_SCHEDULED_ASSESSMENT,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      PUT,
      DELETE_SCHEDULED_ASSESSMENT_URL(),
      data
    );
    return response.data;
  }
);

export const getLearnerAndRmResponse = createAsyncThunk(
  GET_LEARNER_AND_RM_RESPONSE,
  async ({ participant_id }: { participant_id: number }) => {
    const response = await apiRequest(
      GET,
      GET_LEARNER_AND_RM_RESPONSE_URL(participant_id)
    );
    return response.data;
  }
);

const AssessmentsCatlogSlice = createSlice({
  name: "assessmentCatlog",
  initialState,
  reducers: {
    setAssessmentCatlogPayloadToInitials: state => {
      state.numberLevel = initialState.numberLevel;
      state.showFinish = initialState.showFinish;
      state.scheduledDetails = initialState.scheduledDetails;
      state.assessmentCatlogDetailData =
        initialState.assessmentCatlogDetailData;
    },
    setScheduledDetailsToNull: state => {
      state.scheduledDetails = initialState.scheduledDetails;
    },
    setShowFinish: (state, action) => {
      state.showFinish = action.payload;
    },
    handleBackButton: state => {
      state.showFinish = false;
      if (state.numberLevel === 2) {
        state.numberLevel = state.numberLevel - 1;
      }
    },
    handleNextButton: state => {
      if (state.numberLevel === 1) {
        state.showFinish = true;
        state.numberLevel = 2;
      } else {
        showToast(FillAllFields, "error");
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLearnerAndRmResponse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLearnerAndRmResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.learnerAndRMResponse = action.payload;
      })
      .addCase(getLearnerAndRmResponse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteScheduledAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteScheduledAssessment.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteScheduledAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(reviewModificationRequest.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reviewModificationRequest.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(reviewModificationRequest.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteScheduled.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteScheduled.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteScheduled.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(reviewLearnerAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reviewLearnerAssessment.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(reviewLearnerAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentResultForReview.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssessmentResultForReview.fulfilled, (state, action) => {
        state.loading = false;
        state.assessmentResultForReview = action.payload;
      })
      .addCase(getAssessmentResultForReview.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentsToBeReviewed.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssessmentsToBeReviewed.fulfilled, (state, action) => {
        state.loading = false;
        state.assessmentsToBeReviewed = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getAssessmentsToBeReviewed.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(startLearnerAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startLearnerAssessment.fulfilled, (state, action) => {
        state.loading = false;
        state.learnerAssesmentQuestions = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(startLearnerAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createAssessmentCatlog.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAssessmentCatlog.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createAssessmentCatlog.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateAssessmentCatlog.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAssessmentCatlog.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateAssessmentCatlog.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(endLearnerAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(endLearnerAssessment.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(endLearnerAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(requestForModificationAcceessAPI.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(requestForModificationAcceessAPI.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(requestForModificationAcceessAPI.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssinedAssessmentToLearner.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssinedAssessmentToLearner.fulfilled, (state, action) => {
        state.loading = false;
        state.learnerAssignedAssessments = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getAssinedAssessmentToLearner.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getScheduledDetailsByScheduleId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScheduledDetailsByScheduleId.fulfilled, (state, action) => {
        state.loading = false;
        state.scheduledDetails = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getScheduledDetailsByScheduleId.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getScheduledByAssessmentId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScheduledByAssessmentId.fulfilled, (state, action) => {
        state.loading = false;
        state.scheduledAssessmentsById = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getScheduledByAssessmentId.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(scheduleAssessmentCatlog.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(scheduleAssessmentCatlog.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(scheduleAssessmentCatlog.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(editScheduledAssessmentCatlog.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editScheduledAssessmentCatlog.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(editScheduledAssessmentCatlog.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentCatlogList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssessmentCatlogList.fulfilled, (state, action) => {
        state.loading = false;
        state.assessmentCatlogList = action.payload;
      })
      .addCase(getAssessmentCatlogList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentCatlogById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssessmentCatlogById.fulfilled, (state, action) => {
        state.loading = false;
        state.assessmentCatlogDetailData = action.payload;
      })
      .addCase(getAssessmentCatlogById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const {
  setAssessmentCatlogPayloadToInitials,
  setShowFinish,
  handleBackButton,
  handleNextButton,
  setScheduledDetailsToNull,
} = AssessmentsCatlogSlice.actions;
export default AssessmentsCatlogSlice.reducer;
