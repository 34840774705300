import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { PUBLISHED, TAG_ASSESSMENT } from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import SearchBar from "../../../CommonElements/SearchBar";
import QuestionBankCard from "../../../container/GenericCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getAssessmentsToBeReviewed,
  setAssessmentCatlogPayloadToInitials,
} from "../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { getTagsList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AssessmentType,
  CreatedOn,
  Mins,
  ModificationRequest,
  Module,
  Question,
  Select,
  Tags,
  Time,
  Type,
} from "../../../utils/Constant";
import {
  assessmentTypesNames,
  assessmentTypes,
  formatCustomDate,
} from "../../../utils/helper/helper";

import "./style.scss";

const ScheduledAssessments = () => {
  const dispatch = useAppDispatch();
  const { assessmentsToBeReviewed } = useAppSelector(
    state => state.assessmentCatlog
  );
  const { tagList } = useAppSelector(state => state.master);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [tagsListData, setTagsListData] = useState([]);
  const [tagsListDataIdsMap, setTagsListDataIdsMap] = useState({});
  const assessmentOptions = assessmentTypes.map(item => ({
    ...item,
    name: item.value,
  }));

  const handleSearch = (query: string) => {
    const filteredAssessment = assessmentsToBeReviewed?.assessments?.filter(
      assessment => assessment.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredAssessment || []);
    setNoDataFound(!filteredAssessment || filteredAssessment?.length === 0);
  };

  useEffect(() => {
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: selectedFilterValues["assessment_type"],
      tag: selectedFilterValues["tags"],
    };
    getAssessments(params);
    dispatch(getTagsList({ tagType: TAG_ASSESSMENT }));
  }, [dispatch]);

  useEffect(() => {
    const tags = [];
    const tagsMap = {};
    if (tagList) {
      tagList?.tags?.forEach((item, ind) => {
        tagsMap[ind] = item?.ids;
        tags.push({
          name: ind,
          value: item?.tag_name,
          label: item?.tag_name,
        });
      });
    }
    setTagsListData(tags);
    setTagsListDataIdsMap(tagsMap);
  }, [tagList]);

  const getAssessments = async params => {
    dispatch(getAssessmentsToBeReviewed(params)).then((response: any) => {
      if (response?.payload?.assessments?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  const onHeaderDropdownClick = e => {
    e.preventDefault();
    toggleModal();
  };

  const renderAssessmentCatlogCard = (assessment: AssessmentCatlog, index) => {
    return (
      <Col key={index} sm={12} lg={4}>
        <Link
          to={"/assessments/assessment-details"}
          onClick={() => {
            dispatch(setAssessmentCatlogPayloadToInitials());
          }}
          state={{
            assessmentId: assessment?.assessment_id,
            type: assessment?.assessment_type,
            review: true,
          }}
        >
          <QuestionBankCard
            id={index}
            header={assessment?.name}
            footerBadge={
              assessment?.status === PUBLISHED &&
              assessment?.requests?.modification > 0 &&
              ModificationRequest
            }
            footerDate={CreatedOn + formatCustomDate(assessment?.created_at)}
            onHeaderDropdownClick={onHeaderDropdownClick}
            isHideMoreOption
            moduleDetails={[
              {
                label: Type,
                value:
                  assessmentTypesNames[assessment?.assessment_type?.toString()],
                key: `${assessment.id}_${Type}`,
              },
              {
                label: Question,
                value: assessment?.overall_question_count?.toString(),
                key: `${assessment.id}_${Question}`,
              },
              {
                label: Module,
                value: assessment?.module_count?.toString(),
                key: `${assessment.id}_${Module}`,
              },
              {
                label: Time,
                value: assessment?.estimated_time
                  ? `${assessment?.estimated_time?.toString() + Mins}`
                  : "-",
                key: `${assessment.id}_${Time}`,
              },
            ]}
          />
        </Link>
      </Col>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const tagIds = [];
    if (values["tags"]) {
      values["tags"]?.forEach(item => {
        if (tagsListDataIdsMap[item]) tagIds.push(...tagsListDataIdsMap[item]);
      });
    }
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: values["assessment_type"],
      tag: tagIds?.toString() || null,
    };
    getAssessments(params);
  };

  const handleClear = () => {
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: [],
      tag: "",
    };
    getAssessments(params);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const renderAssessmentContent = () => {
    if (noDataFound) {
      return <NoDataFound />;
    } else if (searchResults?.length > 0) {
      return searchResults?.map((assessment, index) =>
        renderAssessmentCatlogCard(assessment, index)
      );
    } else {
      return assessmentsToBeReviewed?.assessments?.map((assessment, index) =>
        renderAssessmentCatlogCard(assessment, index)
      );
    }
  };

  return (
    <div className="page-body page-body-margin schedule-assessments">
      <Card className="p-1">
        <CardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-1">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Tags,
                      key: "tags",
                      tooltipText: `${Select} ${Tags}`,
                      options: tagsListData,
                      isMultiSelect: true,
                    },
                    {
                      label: AssessmentType,
                      key: "assessment_type",
                      tooltipText: `${Select} ${AssessmentType}`,
                      options: assessmentOptions,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderAssessmentContent()}</Row>
    </div>
  );
};

export default ScheduledAssessments;
