import { Href } from "../../utils/Constant";

export const notificationData = [
  {
    img: "wallet.png",
    link: Href,
    title: "New daily offer added",
    subTitle: "New user-only offer added",
    color: "primary",
  },
  {
    img: "shield-dne.png",
    link: Href,
    title: "Product Evaluation",
    subTitle: "Changed to a new workflow",
    color: "info",
  },
  {
    img: "graph.png",
    link: Href,
    title: "Return of a Product",
    subTitle: "452 items were returned",
    color: "warning",
  },
  {
    img: "ticket-star.png",
    link: Href,
    title: "Recently Paid",
    subTitle: "Mastercard payment of $343",
    color: "tertiary",
  },
];

export interface ProfileMessageType {
  id: number;
  name: string;
  icon: "LogoutOutlined";
  link: string;
}

export const profilesMessage: ProfileMessageType[] = [
  {
    id: 1,
    name: "Log Out",
    icon: "LogoutOutlined",
    link: `${process.env.PUBLIC_URL}/login`,
  },
];
