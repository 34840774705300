import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Layout from "../Layout/Layout";
import { scrollToTop } from "../utils/helper/helper";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  SAVE_LOCATION_STATE,
  saveToLocalStorage,
} from "../utils/helper/localStorageutils";

import routes from "./Route";

const LayoutRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (location.state) {
        saveToLocalStorage(SAVE_LOCATION_STATE, JSON.stringify(location.state));
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.state]);

  useEffect(() => {
    scrollToTop();
    if (!location.state) {
      const savedState = getFromLocalStorage(SAVE_LOCATION_STATE);
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        navigate(location.pathname, { replace: true, state: parsedState });
        removeFromLocalStorage(SAVE_LOCATION_STATE);
      }
    }
  }, [location, navigate]);

  return (
    <Routes>
      {routes?.map(({ path, Component }) => (
        <Route element={<Layout />} key={path}>
          <Route index path={path} element={Component} />
        </Route>
      ))}
    </Routes>
  );
};

export default LayoutRoutes;
