const courseApiUrl = `${process.env.REACT_APP_COURSE_API_ENDPOINT}`;
const userApiUrl = `${process.env.REACT_APP_USER_API_ENDPOINT}`;
const assessmentApiUrl = `${process.env.REACT_APP_ASSESSMENT_API_ENDPOINT}`;
const learnerApiUrl = `${process.env.REACT_APP_LEARNER_API_ENDPOINT}`;
export const questionBankTemplteUrl = `${process.env.REACT_APP_QUESTION_BANK_TEMPLTE_URL}`;
export const emailTemplateUrl = `${process.env.REACT_APP_EMAIL_TEMPLATE_URL}`;

// Methods
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

// Thunk names
export const FETCH_DATA = "/get";
export const PUT_DATA = "/put";
export const POST_DATA = "/post";
export const POST_DATA_URL = "/post";
export const GET_COURSE_REQUEST_LIST = "GET_COURSE_REQUEST_LIST";
export const GET_COURSE_REQUEST_LIST_BY_FILTER =
  "GET_COURSE_REQUEST_LIST_BY_FILTER";
export const GET_COURSE_REQUEST_BY_ID = "GET_COURSE_REQUEST_LIST_BY_ID";
export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const APPROVE_COURSE_REQUEST = "APPROVE_COURSE_REQUEST";
export const GET_USER_SPECIFIC_COURSE_LIST = "GET_USER_SPECIFIC_COURSE_LIST";
export const GET_COURSE_LIST = "GET_COURSE_LIST";
export const DOWNLOAD_STAGE_DATA = "DOWNLOAD_STAGE_DATA";
export const GET_COURSE_CONTENT = "GET_COURSE_CONTENT";
export const GET_COURSE_CONTENT_BY_IDS = "GET_COURSE_CONTENT_BY_IDS";
export const GET_PUBLISHED_COURSE_LIST = "GET_PUBLISHED_COURSE_LIST";
export const UPDATE_COURSE_CONTENT = "UPDATE_COURSE_CONTENT";
export const CREATE_COURSE_CONTENT = "CREATE_COURSE_CONTENT";
export const CREATE_LEARNING_OUTCOME = "CREATE_LEARNING_OUTCOME";
export const UPDATE_LEARNING_OUTCOME = "UPDATE_LEARNING_OUTCOME";
export const CREATE_ASSESSMENT = "CREATE_ASSESSMENT";
export const UPDATE_ASSESSMENT = "UPDATE_ASSESSMENT";
export const CREATE_COURSE_MATERIALS = "CREATE_COURSE_MATERIALS";
export const SUBMIT_COURSE_DETAILS_BY_TRAINER =
  "SUBMIT_COURSE_DETAILS_BY_TRAINER";
export const REVIEW_LEARNING_OUTCOME = "REVIEW_LEARNING_OUTCOME";
export const GET_PRE_REQUISITES = "GET_PRE_REQUISITES";
export const CREATE_LESSON_PLAN = "CREATE_LESSON_PLAN";
export const UPDATE_LESSON_PLAN = "UPDATE_LESSON_PLAN";
export const GET_QUESTION_BANK = "GET_QUESTION_BANK";
export const ADD_COMMENT = "ADD_COMMENT";
export const GET_MODULE = "GET_MODULE";
export const GET_DESIGNATION = "GET_DESIGNATION";
export const GET_DESIGNATION_BY_ID = "GET_DESIGNATION_BY_ID";
export const GET_BUSINESS_GROUP = "GET_BUSINESS_GROUP";
export const GET_POLICY = "GET_POLICY";
export const GET_INTENDED_AUDIENCE = "GET_INTENDED_AUDIENCE";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const REVIEW_COURSE = "REVIEW_COURSE";
export const UPDATE_COMMENT_STATUS = "UPDATE_COMMENT_STATUS";
export const GET_REGION = "/getRegion";
export const GET_TOOLS = "/GET_TOOLS";
export const GET_TRAINING_AID = "/GET_TRAINING_AID";
export const GET_RESOURCE = "/getResource";
export const GET_REFERENCE = "/getRefernce";
export const GET_LEARNING_OUTCOME = "/getLearningOutcome";
export const GET_DOMAIN = "/getDomain";
export const GET_COMPETENCY = "/getCompetency";
export const GET_PROFICIENCY = "/getProficiency";
export const GET_CURICULUM = "/getCuriculum";
export const GET_USERS = "/getUsers";
export const GET_USERS_DETAIL = "/getUsersDetail";
export const GET_COURSE_REQUEST = "/getCourseRequest";
export const GET_COURSE = "/getCourse";
export const PUT_ASSIGN_REQUEST = "/putAssign";
export const PUT_ASSIGN_REQUEST_EXISTING = "PUT_ASSIGN_REQUEST_EXISTING";
export const GET_ASSESSMENT_REQUEST = "/getAssessment";
export const GET_ASSESSMENT_CATLOG_BY_ID = "/GET_ASSESSMENT_CATLOG_BY_ID";
export const GET_SCHEDULED_BY_ASSESSMENT_ID = "/GET_SCHEDULED_BY_ASSESSMENT_ID";
export const GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID =
  "/GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID";
export const GET_ASSIGNED_ASSESSMENT_TO_LEARNER =
  "/GET_ASSIGNED_ASSESSMENT_TO_LEARNER";
export const CREATE_ASSESSMENT_CATLOG = "/CREATE_ASSESSMENT_CATLOG";
export const GET_LEARNER_AND_RM_RESPONSE = "/GET_LEARNER_AND_RM_RESPONSE";
export const GET_ASSESSEMENT_RESULT_FOR_REVIEW =
  "/GET_ASSESSEMENT_RESULT_FOR_REVIEW";
export const START_LEARNER_ASSESSMENT = "/START_LEARNER_ASSESSMENT";
export const ASSESSMENTS_TO_BE_REVIEWED = "/ASSESSMENTS_TO_BE_REVIEWED";
export const END_LEARNER_ASSESSMENT = "/END_LEARNER_ASSESSMENT";
export const REQUEST_FOR_MODIFICATION = "/REQUEST_FOR_MODIFICATION";
export const SCHEDULE_ASSESSMENT_CATLOG = "/SCHEDULE_ASSESSMENT_CATLOG";
export const EDIT_SCHEDULE_ASSESSMENT_CATLOG =
  "/EDIT_SCHEDULE_ASSESSMENT_CATLOG";
export const BULK_UPLOAD_USERS = "/BULK_UPLOAD_USERS";
export const REVIEW_MODIFICATION_REQUEST = "/REVIEW_MODIFICATION_REQUEST";
export const DELETE_SCHEDULED = "/DELETE_SCHEDULED";
export const UPDATE_ASSESSMENT_CATLOG = "/UPDATE_ASSESSMENT_CATLOG";
export const REVIEW_LEARNER_ASSESSMENT = "/REVIEW_LEARNER_ASSESSMENT";
export const DELETE_SCHEDULED_ASSESSMENT = "/DELETE_SCHEDULED_ASSESSMENT";
export const GET_ASSESSMENT_CATLOG_LIST = "/GET_ASSESSMENT_CATLOG_LIST";
export const DELETE_ASSESSMENT_REQUEST = "/deleteAssessment";
export const GET_CURRICULUM_LIST = "GET_CURRICULUM_LIST";
export const CREATE_CURRICULUM_LIST = "CREATE_CURRICULUM_LIST";
export const DELETE_CURRICULUM_COURSE = "DELETE_CURRICULUM_COURSE";
export const GET_CURRICULUM_BY_ID = "GET_CURRICULUM_BY_ID";
export const GET_QUESTION_BANK_LIST = "GET_QUESTION_BANK_LIST";
export const BULK_UPLOAD_QB = "BULK_UPLOAD_QB";
export const GET_MODULE_QB_BY_IDS = "GET_MODULE_QB_BY_IDS";
export const GET_RA_BY_MODULE_ID = "GET_RA_BY_MODULE_ID";
export const GET_QUESTION_AND_OPTION_BY_MODULE =
  "GET_QUESTION_AND_OPTION_BY_MODULE";
export const DELETE_MODULE_OR_QB = "DELETE_MODULE_OR_QB";
export const CREATE_RA_FOR_QB = "CREATE_RA_FOR_QB";
export const UPDATE_RA_FOR_QB = "UPDATE_RA_FOR_QB";
export const UPDATE_QUESTION_IN_QB = "UPDATE_QUESTION_IN_QB";
export const GET_QUESTIONS_LIST = "GET_QUESTIONS_LIST";
export const CREATE_QUESTION_BANK = "CREATE_QUESTION_BANK";
export const UPDATE_QUESTION_BANK = "UPDATE_QUESTION_BANK";
export const GET_ASSESSMENT_MODULE_BY_QB_ID = "GET_ASSESSMENT_MODULE_BY_QB_ID";
export const GET_ALL_QUESTIONS_AND_MODULES = "GET_ALL_QUESTIONS_AND_MODULES";
export const GET_POLICY_LIST = "GET_POLICY_LIST";
export const GET_SCORM_DATA = "GET_SCORM_DATA";
export const SYNC_SCORM_DATA = "SYNC_SCORM_DATA";
export const CREATE_POLICY = "CREATE_POLICY";
export const UPDATE_POLICY = "UPDATE_POLICY";
export const DELETE_POLICY = "DELETE_POLICY";
export const GET_SGA_LIST = "GET_SGA_LIST";
export const GET_ROLES = "GET_ROLES";
export const GET_EXPERTISELEVEL_LIST = "GET_EXPERTISELEVEL_LIST";
export const GET_ASSESSMENT_BY_PARTICIPANT_ID =
  "GET_ASSESSMENT_BY_PARTICIPANT_ID";
export const SUBMIT_LEARNING_ASSESSMENT = "SUBMIT_LEARNING_ASSESSMENT";
export const GET_FILTER_ROLES = "GET_FILTER_ROLES";
export const GET_SBU = "GET_SBU";
export const GET_LEVEL = "GET_LEVEL";
export const GET_ALL_SELF_ASSESSMENT = "GET_ALL_SELF_ASSESSMENT";
export const GET_CURRICULUM_BY_ROLE = "GET_CURRICULUM_BY_ROLE_ID";
export const DELETE_SGA_COURSE = "DELETE_SGA_COURSE";
export const GET_GRADES = "GET_GRADES";
export const GET_USER_MASTER = "GET_USER_MASTER";
export const GET_TAGS = "GET_TAGS";
export const GET_SGA_BY_ID = "GET_SGA_BY_ID";
export const MAP_TO_SGA = "MAP_TO_SGA";
export const GET_ASSIGNED_ASSESSMENT_FOR_LEARNER =
  "GET_ASSIGNED_ASSESSMENT_FOR_LEARNER";
export const CREATE_EXTENSION_REQUEST = "CREATE_EXTENSION_REQUEST_URL";
export const UPDATE_RE_ATTEMPT_DAYS = "UPDATE_RE_ATTEMPT_DAYS";

export const GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID =
  "GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID";
export const GET_ILP_COURSE = "GET_ILP_COURSE";
export const GET_UN_MAPPED_COURSE = "GET_UN_MAPPED_COURSE";
export const GET_MAPPED_CURRICULUM_LIST = "GET_MAPPED_CURRICULUM_LIST";
export const GET_MAPPED_SGA_LIST = "GET_MAPPED_SGA_LIST";
export const GET_OVERALL_STATISTICS = "GET_OVERALL_STATISTICS";
export const GET_TRAINING_HISTORY = "GET_TRAINING_HISTORY";
export const GET_MAPPED_ASSESSMENT = "GET_MAPPED_ASSESSMENT";
export const GET_PENDING_SKILL_UPDATE = "GET_PENDING_SKILL_UPDATE";
export const UPLOAD_SKILL_DOCS = "UPLOAD_SKILL_DOCS";
export const APPROVE_SKILL_DOCS = "APPROVE_SKILL_DOCS";
export const GET_LO_FOR_ASSESSMENT = "GET_LO_FOR_ASSESSMENT";
export const GET_LIST_OF_SGA = "GET_LIST_OF_SGA";
export const MAP_SGA = "MAP_SGA";
export const MAP_CURRICULUM = "MAP_CURRICULUM";
export const MAP_COURSE = "MAP_COURSE";
export const APPROVE_MAPPED_YEAR = "APPROVE_MAPPED_YEAR";
export const MAP_YEAR = "MAP_YEAR";
export const GET_COURSE_COUNT_FOR_YEAR = "GET_COURSE_COUNT_FOR_YEAR";
export const GET_ASSESSMENT_BY_STATUS = "GET_ASSESSMENT_BY_STATUS";
export const MAP_ASSESSMENT = "MAP_ASSESSMENT";
export const GET_MAPPED_CERTIFICATION_LIST = "GET_MAPPED_CERTIFICATION_LIST";

// Master endpoints
export const PUT_DATA_URL = (id: string) => `${courseApiUrl} ${id}`;
export const GET_REGION_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=region`;
export const GET_DESIGNATION_LIST_URL = () =>
  `${userApiUrl}/master?request_type=designation`;
export const GET_DESIGNATION_BY_ID_URL = id =>
  `${userApiUrl}/master?request_type=designation&id=${id}`;
export const GET_BUSINESS_GROUP_LIST_URL = () =>
  `${userApiUrl}/master?request_type=business-group`;
export const GET_RESOURCE_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=resource`;
export const GET_REFERNCE_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=reference`;
export const GET_DOMAIN_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=domain`;
export const GET_PROFICIENCY_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=proficiency`;
export const GET_COMPETENCY_REQUEST_URL = (domainId: string) =>
  `${courseApiUrl}/master?request_type=competency&domain=${domainId}`;
export const GET_PRE_REQUISITES_URL = () =>
  `${courseApiUrl}/master?request_type=prerequisite`;
export const GET_TOOLS_URL = () =>
  `${courseApiUrl}/master?request_type=resource&type=tool`;
export const GET_TRAINING_AID_URL = () =>
  `${courseApiUrl}/master?request_type=resource&type=training_aid`;
export const GET_LEARNING_OUTCOME_REQUEST_URL = (
  domainId: string,
  competencyId: string,
  proficiencyId: string
) =>
  `${courseApiUrl}/master?request_type=learning-outcome&domain=${domainId}&competency=${competencyId}&proficiency=${proficiencyId}`;
export const GET_CURICULUM_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=curriculum`;
export const REVIEW_LEARNING_OUTCOME_URL = () =>
  `${courseApiUrl}/course?request_type=review-learning-outcome`;
export const GET_QUESTION_BANK_URL = () =>
  `${assessmentApiUrl}/master?request_type=question-bank`;
export const GET_MODULES_URL = id =>
  `${assessmentApiUrl}/master?request_type=module&question_bank_id=${id}`;
export const GET_POLICY_URL = () =>
  `${assessmentApiUrl}/master?request_type=policy`;
export const GET_INTENDED_AUDIENCE_URL = () =>
  `${courseApiUrl}/master?request_type=intended-audience`;
export const GET_ROLES_URL = (getMappedRole?: string) => {
  let url = `${userApiUrl}/master?request_type=job-role`;
  if (getMappedRole) {
    url += `&get_mapped_role=${getMappedRole}`;
  }
  return url;
};
export const GET_FILTER_ROLES_URL = () =>
  `${userApiUrl}/master?request_type=job-role`;
export const GET_GRADES_URL = () => `${userApiUrl}/master?request_type=grade`;
export const GET_TAGS_URL = (tagType: string) =>
  `${assessmentApiUrl}/master?request_type=tag&type=${tagType}`;

export const GET_ASSESSMENT_ROLES_URL = () =>
  `${userApiUrl}/master?request_type=job-role`;
export const GET_SBU_URL = () => `${userApiUrl}/master?request_type=sbu`;
export const GET_LEVEL_URL = () => `${userApiUrl}/master?request_type=level`;
export const GET_USER_MASTER_WITHOUT_FILTER_URL = () =>
  `${userApiUrl}/master?request_type=user`;
export const GET_USER_MASTER_URL = (
  roleId: string,
  levelId: string,
  sbuId: string
) =>
  `${userApiUrl}/master?request_type=user&role_id=${roleId}&level_id=${levelId}&sbu_id=${sbuId}`;
export const BULK_UPLOAD_USERS_URL = () =>
  `${userApiUrl}/user?request_type=participant`;

// Course request endpoints
export const GET_COURSE_REQUEST_LIST_URL = (status: string) =>
  `${courseApiUrl}/course?request_type=course-request&status=${status}`;
export const GET_COURSE_REQUEST_BY_ID_URL = (id: string) =>
  `${courseApiUrl}/course?request_type=course-request&id=${id}`;
export const GET_COURSE_REQUEST_FILTERS_URL = (
  status: string,
  filters: string
) =>
  `${courseApiUrl}/course?request_type=course-request&status=${status}&course_request_type=${filters}`;
export const GET_COURSE_REQUEST_URL = (status: string) =>
  `${courseApiUrl}/course?request_type=course-request?status=${status}`;
export const CREATE_COURSE_REQUEST_URL = () =>
  `${courseApiUrl}/course?request_type=course-request`;
export const UPDATE_COURSE_REQUEST_URL = () =>
  `${courseApiUrl}/course?request_type=course-request`;
export const APPROVE_COURSE_REQUEST_URL = () =>
  `${courseApiUrl}/course?request_type=review-course-request`;

// Course endpoints
export const GET_COURSE_LIST_API = (
  status: string,
  course_type: string,
  domain: string | null
) => {
  let url = `${courseApiUrl}/course?request_type=course-content&status=${status}&course_type=${course_type}`;
  if (domain) url += `&domain=${domain}`;
  return url;
};
export const GET_USER_SPECIFIC_COURSE_LIST_API = (
  status: string,
  course_type: string,
  user_id: string
) =>
  `${courseApiUrl}/course?request_type=course-content&status=${status}&course_type=${course_type}&user_id=${user_id}`;
export const GET_COURSE_CONTENT_API = (id: string) =>
  `${courseApiUrl}/course?request_type=course-content&id=${id}`;
export const CREATE_COURSE_CONTENT_API = () =>
  `${courseApiUrl}/course?request_type=course-content`;
export const UPDATE_COURSE_CONTENT_API = () =>
  `${courseApiUrl}/course?request_type=course-content`;
export const REVIEW_COURSE_API = () =>
  `${courseApiUrl}/course?request_type=review-course`;
export const GET_PUBLISHED_COURSE_LIST_API = () =>
  `${courseApiUrl}/course?request_type=course-content&status=published`;
export const SUBMIT_COURSE_DETAILS_BY_TRAINER_URL = () =>
  `${courseApiUrl}/course?request_type=course-content`;
export const DOWNLOAD_COURSE_STAGE_DATA_API = (id: string, type: string) =>
  `${courseApiUrl}/course?request_type=course-content&id=${id}&type=${type}`;

export const GET_COURSE_CONTENT_BY_IDS_API = (id: number[]) =>
  `${courseApiUrl}/course?request_type=course-content&id=${id}&required_parameter=id,course_name,course_type`;

// Course Stages endpoints
export const CREATE_LEARNING_OUTCOME_URL = () =>
  `${courseApiUrl}/course?request_type=learning-outcome`;
export const UPDATE_LEARNING_OUTCOME_URL = () =>
  `${courseApiUrl}/course?request_type=learning-outcome`;
export const GET_ASSESSMENT_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=course-assessment&course_id=${id}`;
export const CREATE_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=course-assessment`;
export const UPDATE_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=course-assessment`;
export const DELETE_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=course-assessment`;
export const CREATE_COURSE_MATERIALS_URL = () =>
  `${courseApiUrl}/course?request_type=course-material`;
export const CREATE_LESSON_PLAN_URL = () =>
  `${courseApiUrl}/course?request_type=lesson-plan`;
export const UPDATE_LESSON_PLAN_URL = () =>
  `${courseApiUrl}/course?request_type=lesson-plan`;
export const ADD_COMMENT_URL = () =>
  `${courseApiUrl}/course?request_type=review-learning-outcome`;
export const GET_ASSESSMENT_BY_PARTICIPANT_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=start&participant_id=${id}`;
export const SUBMIT_LEARNING_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=end`;
export const UPDATE_COMMENT_STATUS_URL = () =>
  `${courseApiUrl}/course?request_type=comment-status`;
export const GET_ASSIGNED_ASSESSMENT_FOR_LEARNER_URL = (
  status,
  assessmentType,
  tag
) => {
  let url = `${assessmentApiUrl}/assessment?request_type=participant&status=${status}`;
  if (assessmentType?.length > 0) {
    url += `&assessment_type=${assessmentType}`;
  }
  if (tag?.length > 0) {
    url += `&tag=${tag}`;
  }
  return url;
};
export const CREATE_EXTENSION_REQUEST_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=raise_request`;
export const GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=participant&participant_id=${id}`;
export const UPDATE_RE_ATTEMPT_DAYS_API = () =>
  `${assessmentApiUrl}/assessment?request_type=reattempt`;
// Users endpoints
export const GET_ALL_USERS_REQUEST_URL = (role: string) =>
  `${userApiUrl}/master?request_type=user&role=${role}`;
export const GET_USER_DETAILS_REQUEST_URL = (id: number[]) =>
  `${userApiUrl}/master?id=${id}&request_type=user`;
export const PUT_ASSIGN_URL = () =>
  `${courseApiUrl}/course?request_type=assign`;
export const UPLOAD_FILE_URL = () =>
  `${userApiUrl}/common?request_type=file-upload`;

export const GET_CURRICULUM_LIST_API = (
  status: string,
  filters?: {
    domain?: string[];
    role?: string[];
    specialCurriculum?: boolean;
  }
) => {
  let queryString = `${courseApiUrl}/course?request_type=curriculum&status=${status}`;

  if (filters?.domain && filters.domain.length > 0) {
    const domainQuery = filters.domain.map(id => `domain_id=${id}`).join("&");
    queryString += `&${domainQuery}`;
  }

  if (filters?.role && filters.role.length > 0) {
    const roleQuery = filters.role.map(id => `role_id=${id}`).join("&");
    queryString += `&${roleQuery}`;
  }

  if ("specialCurriculum" in filters) {
    queryString += `&is_special_curriculum=${filters?.specialCurriculum ? "1" : "0"}`;
  }

  return queryString;
};

export const CREATE_CURRICULUM = () =>
  `${courseApiUrl}/course?request_type=curriculum`;

export const GET_CURRICULUM_BY_ID_API = (id: number) =>
  `${courseApiUrl}/course?request_type=curriculum&id=${id}`;

// Question Bank API endpoints
export const GET_QUESTION_BANK_LIST_URL = (status: string) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&status=${status}`;
export const GET_QUESTION_BANK_LIST_WITH_BOTH_URL = (
  status: string,
  tag: string,
  self_assessment: string
) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&status=${status}&tag=${tag}&self_assessment=${self_assessment}`;
export const GET_QUESTION_BANK_LIST_WITH_TAG_URL = (
  status: string,
  tag: string
) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&status=${status}&tag=${tag}`;
export const GET_QUESTION_BANK_LIST_WITH_SELF_URL = (
  status: string,
  self_assessment: string
) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&status=${status}&self_assessment=${self_assessment}`;
export const CREATE_QUESTION_BANK_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=question-bank`;
export const UPDATE_QUESTION_BANK_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=question-bank`;
export const GET_QUESTIONS_LIST_URL = (module_id: string, page: number) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&module_id=${module_id}&page=${page}`;
export const GET_MODULE_QB_BY_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=module&question_bank_id=${id}`;
export const GET_ASSESSMENT_MODULE_QB_BY_ID_URL = (id: number) =>
  `${assessmentApiUrl}/master?request_type=module&question_bank_id=${id}`;
export const GET_ALL_QUESTIONS_AND_MODULES_URL = (question_bank_id: number) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&question_bank_id=${question_bank_id}`;
export const BULK_UPLOAD_QB_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&id=${id}`;
export const CREATE_RA_FOR_QB_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=remedial-action`;
export const UPDATE_RA_FOR_QB_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=remedial-action`;
export const UPDATE_QUESTION_IN_QB_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=question`;
export const GET_RA_BY_MODULE_ID_URL = (module_id: number) =>
  `${assessmentApiUrl}/assessment?request_type=remedial-action&module_id=${module_id}`;
export const DELETE_MODULE_OR_QB_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=question-bank`;
export const GET_QUESTION_AND_OPTION_BY_MODULE_URL = (
  module_id: string,
  page: number
) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&module_id=${module_id}&page_no=${page}`;
// Policy endpoints
export const UPSERT_POLICY_API = (type?: string) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=policy`;

  if (type !== undefined) {
    queryString += `&type=${type}`;
  }

  return queryString;
};

export const DELETE_POLICY_API = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=policy&id=${id}`;

//SGA endpoints
export const GET_SGA_LIST_API = () =>
  `${assessmentApiUrl}/assessment?request_type=sga`;

export const GET_ALL_SELF_ASSESSMENT_API = () =>
  `${assessmentApiUrl}/assessment?request_type=competency`;

export const GET_CURRICULUM_BY_ROLE_ID = (
  role_id: number,
  level_id?: number,
  course_type?: string[]
) => {
  let queryString = `${courseApiUrl}/course?request_type=curriculum&role_id=${role_id}`;

  if (level_id !== undefined) {
    queryString += `&level_id=${level_id}`;
  }

  if (course_type) {
    queryString += `&course_type=${course_type}`;
  }

  return queryString;
};

export const GET_SGA_BY_ID_API = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=sga&id=${id}`;

// Assessment Catlog
export const GET_ASSESSMENT_CATLOG_LIST_URL = (status, assessmentType, tag) => {
  let url = `${assessmentApiUrl}/assessment?request_type=assessment&status=${status}`;
  if (assessmentType?.length > 0) {
    url += `&assessment_type=${assessmentType}`;
  }
  if (tag?.length > 0) {
    url += `&tag=${tag}`;
  }
  return url;
};

export const GET_ASSESSMENT_CATLOG_BY_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=assessment&id=${id}`;
export const CREATE_ASSESSMENT_CATLOG_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=assessment`;
export const SCHEDULE_ASSESSMENT_CATLOG_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=schedule`;
export const GET_SCHEDULED_BY_ASSESSMENT_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=schedule&assessment_id=${id}`;
export const REQUEST_FOR_MODIFICATION_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=raise_request`;
export const REVIEW_MODIFICATION_REQUEST_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=review_request`;
export const DELETE_SCHEDULED_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=schedule`;
export const GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID_URL = (
  schedule_id: number,
  assessment_id: number
) =>
  `${assessmentApiUrl}/assessment?request_type=review&schedule_id=${schedule_id}&assessment_id=${assessment_id}`;
export const GET_COMPETENCY_SCHEDULED_DETAILS_BY_SCHEDULE_ID_URL = (
  assessment_id: number
) =>
  `${assessmentApiUrl}/assessment?request_type=review&assessment_id=${assessment_id}`;
export const GET_ASSIGNED_ASSESSMENT_TO_LEARNER_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=participant&status=current`;
export const START_LEARNER_ASSESSMENT_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=start&participant_id=${id}`;
export const END_LEARNER_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=end`;
export const ASSESSMENTS_TO_BE_REVIEWED_URL = (assessmentType, tag) => {
  let url = `${assessmentApiUrl}/assessment?request_type=review&status=under_review`;
  if (assessmentType?.length > 0) {
    url += `&assessment_type=${assessmentType}`;
  }
  if (tag?.length > 0) {
    url += `&tag=${tag}`;
  }
  return url;
};
export const GET_ASSESSMENT_RESULT_FOR_REVIEW_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=review&participant_id=${id}`;
export const REVIEW_LEARNER_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=review`;
export const DELETE_SCHEDULED_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=review`;
export const MAP_TO_SGA_API = () =>
  `${assessmentApiUrl}/assessment?request_type=sga`;
export const GET_LEARNER_AND_RM_RESPONSE_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=competency&participant_id=${id}`;

// Scorm endpoint
export const GET_SCORM_DATA_API = (courseId: string) =>
  `${learnerApiUrl}/learner?request_type=scorm&course_id=${courseId}`;

export const SYNC_SCORM_DATA_API = () =>
  `${learnerApiUrl}/learner?request_type=scorm`;

//ILP endpoints
export const GET_ILP_COURSE_URL = (
  courseType: string,
  selfEnrollment: boolean,
  fyYear: string,
  userId?: string
) => {
  let queryString = `${courseApiUrl}/course?request_type=ilp&course_type=${courseType}&year=${fyYear}&type=mapped`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  queryString += `&is_self_enrollment=${selfEnrollment ? "1" : "0"}`;
  return queryString;
};

export const GET_UN_MAPPED_COURSE_URL = (userId?: number) => {
  let queryString = `${courseApiUrl}/course?request_type=ilp&type=unmapped`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_MAPPED_CURRICULUM_LIST_URL = (userId?: number) => {
  let queryString = `${courseApiUrl}/course?request_type=mapped-curriculum`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_MAPPED_SGA_LIST_URL = (type: string, userId?: number) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=mapped-sga&type=${type}`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_OVERALL_STATISTICS_URL = (
  selfEnrollment: boolean,
  userId?: number
) => {
  let queryString = `${courseApiUrl}/course?request_type=statistics&is_self_enrolled=${selfEnrollment ? "1" : "0"}`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_TRAINING_HISTORY_URL = (userId?: number) => {
  let queryString = `${courseApiUrl}/course?request_type=ilp&type=history`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_MAPPED_ASSESSMENT_URL = (fyYear: string, userId?: number) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=ilp&year=${fyYear}`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_LIST_OF_SGA_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=sga&status=published`;

export const MAP_SGA_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=map`;

export const MAP_COURSE_RELEVANT_URL = () => {
  return `${courseApiUrl}/course?request_type=map`;
};

export const MAP_YEAR_URL = () => {
  return `${courseApiUrl}/course?request_type=map`;
};

export const GET_COURSE_COUNT_FOR_YEAR_URL = (year: string, userId: string) => {
  return `${courseApiUrl}/course?request_type=ilp&type=course_count&user_id=${userId}&year=${year}`;
};

export const GET_ASSESSMENT_BY_STATUS_URL = (status, rqquestType, userId) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=${rqquestType}&status=${status}`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const MAP_ASSESSMENT_URL = () => {
  return `${assessmentApiUrl}/assessment?request_type=map`;
};

export const GET_PENDING_SKILL_UPDATE_URL = (userId?: number) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=skill-document`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_LO_FOR_ASSESSMENT_URL = (ids?: string) => {
  return `${courseApiUrl}/course?request_type=course-content&learning_outcome=1&ids=${ids}`;
};

export const UPLOAD_SKILL_DOCS_URL = () => {
  return `${assessmentApiUrl}/assessment?request_type=skill-document`;
};

export const APPROVE_SKILL_DOCS_URL = () => {
  return `${assessmentApiUrl}/assessment?request_type=skill-document`;
};

export const GET_MAPPED_CERTIFICATION_LIST_URL = () => {
  return `${assessmentApiUrl}/assessment?request_type=certification`;
};
