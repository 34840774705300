import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import JustifyTabs from "../../../CommonElements/JustifyTabs";
import UserProfileCard from "../../../container/UserProfileCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getUserDetailsList,
  setUserDetailsToInitialState,
} from "../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../Service";
import {
  CWSAndCatRecId,
  Contact,
  SUBNo,
  RMName,
  RMEmail,
  RMContactNumber,
  Location,
  Mail,
} from "../../../utils/Constant";
import { userManagementTabs } from "../../../utils/helper/helper";
import { UserProfileInformationDetails } from "../../../utils/helper/propTypes";
import AssignedILP from "../../MyLearningPlan/AssignedILP";
import ILPOverview from "../../MyLearningPlan/ILPOverview";
import ILPSelfEnrollment from "../../MyLearningPlan/ILPSelfEnrollment";
import ILPSga from "../../MyLearningPlan/ILPSga";
import ILPTrainingHistory from "../../MyLearningPlan/ILPTrainingHistory";
import ILPUnMapped from "../../MyLearningPlan/ILPUnMapped";
import PendingSkillUpdate from "../../MyLearningPlan/PendingSkillUpdate";
import ILPAssessment from "../ILPAssessment";

const UserDetails = () => {
  const location = useLocation();
  const { userId } = location.state || "";
  const dispatch = useAppDispatch();
  const [userProfileData, setUserProfileData] = useState(null);
  const params = new URLSearchParams(location.search);
  const { userDetailsList } = useAppSelector(state => state.user);
  const statusParam = params.get("status");
  const [activeTab, setActiveTab] = useState<string>(statusParam || "overview");

  useEffect(() => {
    dispatch(getUserDetailsList({ id: [userId] }));
    return () => {
      dispatch(setUserDetailsToInitialState());
    };
  }, []);

  useEffect(() => {
    if (userDetailsList?.users[0]) {
      const mapUserToProfileDetails = (
        user: any
      ): UserProfileInformationDetails => ({
        userProfileUrl: dynamicImage("dashboard-4/user.png"),
        name: user.user_name,
        designation: user.designation,
        userDetails: [
          {
            icon: "LocationOnOutlined",
            title: Location,
            detail: "-",
          },
          {
            icon: "BadgeOutlined",
            title: CWSAndCatRecId,
            detail: "-",
          },
          {
            icon: "EmailOutlined",
            title: Mail,
            detail: user.email,
          },
          {
            icon: "PhoneOutlined",
            title: Contact,
            detail: "-",
          },
          {
            icon: "PinOutlined",
            title: SUBNo,
            detail: "-",
          },
          {
            icon: "PersonOutline",
            title: RMName,
            detail: user.mapped_rm?.length > 0 ? user.mapped_rm[0].name : "-",
          },
          {
            icon: "EmailOutlined",
            title: RMEmail,
            detail:
              user.mapped_rm?.length > 0 && user.mapped_rm[0].email
                ? user.mapped_rm[0].email
                : "-",
          },
          {
            icon: "PhoneOutlined",
            title: RMContactNumber,
            detail: "-",
          },
        ],
      });
      const constructedProfileData = userDetailsList.users.map(
        mapUserToProfileDetails
      );

      setUserProfileData(constructedProfileData[0]);
    }
  }, [userDetailsList]);

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
  };
  return (
    <div className="page-body page-body-margin">
      <UserProfileCard userData={userProfileData || {}} />
      <Card className="p-1">
        <CardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={userManagementTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      {activeTab === "overview" && <ILPOverview userId={userId} />}
      {activeTab === "assigned_ilp" && <AssignedILP userId={userId} />}
      {activeTab === "self_enrollment" && <ILPSelfEnrollment userId={userId} />}
      {activeTab === "unmapped" && <ILPUnMapped userId={userId} />}
      {activeTab === "sga" && <ILPSga userId={userId} />}
      {activeTab === "pending_skill_update" && (
        <PendingSkillUpdate userId={userId} />
      )}
      {activeTab === "assessment" && <ILPAssessment userId={userId} />}
      {activeTab === "training_history" && (
        <ILPTrainingHistory userId={userId} />
      )}
    </div>
  );
};

export default UserDetails;
