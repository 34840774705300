import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col } from "reactstrap";

import { hasPermissionToComponent } from "../../utils/helper/permission";
import H4 from "../Headings/H4Element";
import MUIIcons from "../MUIIcon/MUIIcons";

const CommonBreadcrumb = () => {
  const location = useLocation();
  const [firstPart, secondPart, thirdPart, fourthPart] = location.pathname
    .split("/")
    .slice(1);

  const getProperPath = () => {
    if (firstPart === "dashboard" || firstPart === "user-management") {
      return `${process.env.PUBLIC_URL}/${firstPart}`;
    }
    if (
      hasPermissionToComponent("COURSE_MANAGEMENT.COURSE_REQUEST") &&
      firstPart === "course-management"
    ) {
      return `${process.env.PUBLIC_URL}/${firstPart}/course-request`;
    }
    if (
      !hasPermissionToComponent("COURSE_MANAGEMENT.COURSE_REQUEST") &&
      firstPart === "course-management"
    ) {
      return `${process.env.PUBLIC_URL}/${firstPart}/course-list`;
    }
    if (firstPart === "assessments") {
      return `${process.env.PUBLIC_URL}/${firstPart}/assessments-list`;
    }
    if (firstPart === "master-data") {
      return `${process.env.PUBLIC_URL}/${firstPart}/question-bank-list`;
    }
    if (secondPart === "course-list" || secondPart === "course-request")
      return `${process.env.PUBLIC_URL}/${firstPart}/${secondPart}`;
  };

  return (
    <Col
      xs="6"
      xl="6"
      className="page-title d-flex flex-column align-items-start gap-1"
    >
      <H4 className="f-w-700 text-capitalize text-white">
        {fourthPart?.replaceAll("_", " ") ||
          thirdPart?.replaceAll("_", " ") ||
          secondPart?.replaceAll("_", " ") ||
          firstPart?.replaceAll("_", " ")}
      </H4>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="text-white"
            state={{ ...location.state }}
          >
            <MUIIcons size={24} iconName="HomeOutlined" />
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="f-w-400 text-capitalize primary-text-color">
          <Link
            to={getProperPath()}
            className={
              !secondPart && !thirdPart && !fourthPart
                ? "primary-text-color"
                : "text-white"
            }
            state={{ ...location.state }}
          >
            {firstPart?.replaceAll("_", " ")}
          </Link>
        </BreadcrumbItem>
        {secondPart && (
          <BreadcrumbItem className="f-w-400 text-capitalize primary-text-color">
            {!thirdPart && !fourthPart ? (
              <span className="primary-text-color">
                {secondPart.replaceAll("_", " ")}
              </span>
            ) : (
              <Link
                to={`${process.env.PUBLIC_URL}/${firstPart}/${secondPart}`}
                state={{ ...location.state }}
                className="text-white"
              >
                {secondPart.replaceAll("_", " ")}
              </Link>
            )}
          </BreadcrumbItem>
        )}
        {thirdPart && (
          <BreadcrumbItem className="f-w-400 text-capitalize primary-text-color">
            {!fourthPart ? (
              <span className="primary-text-color">
                {thirdPart.replaceAll("_", " ")}
              </span>
            ) : (
              <Link
                to={`${process.env.PUBLIC_URL}/${firstPart}/${secondPart}/${thirdPart}`}
                state={{ ...location.state }}
                className="text-white"
              >
                {thirdPart.replaceAll("_", " ")}
              </Link>
            )}
          </BreadcrumbItem>
        )}
        {fourthPart && (
          <BreadcrumbItem className="f-w-400 text-capitalize primary-text-color active">
            {fourthPart.replaceAll("_", " ")}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </Col>
  );
};

export default CommonBreadcrumb;
