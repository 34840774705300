import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { H2 } from "../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import SliderModal from "../../../../CommonElements/SliderModal";
import ActivityLogContainer from "../../../../container/ActivityLogsContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  ActivityLogs,
  Course,
  DurationCount,
  DurationType,
  EffectiveFrom,
  Grades,
  Policy,
  SelfAssessment,
} from "../../../../utils/Constant";
import { formatDateTime } from "../../../../utils/helper/helper";

const AuditLog = ({ auditLogs, handleActivityModal, showActivityLog }) => {
  const [userNameMap, setUserNameMap] = useState(null);
  const dispatch = useAppDispatch();

  const { usersList } = useAppSelector(state => state.user);

  useEffect(() => {
    if (auditLogs?.length > 0 && showActivityLog) {
      dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    }
  }, [showActivityLog]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  const transformLogs = logs => {
    return logs?.map(logEntry => {
      const transformedData = [];

      const {
        course,
        grades,
        policy,
        competency_assessment,
        duration_type,
        duration_count,
        effective_from,
      } = logEntry.logs;
      if (course) {
        transformedData.push({
          sourceLabel: Course,
          previousValue: course.old_value.map(c => c.course_name).join(", "),
          currentValue: course.new_value.map(c => c.course_name).join(", "),
        });
      }

      if (grades) {
        transformedData.push({
          sourceLabel: Grades,
          previousValue: grades.old_value.map(g => g.name).join(", "),
          currentValue: grades.new_value.map(g => g.name).join(", "),
        });
      }

      if (policy) {
        transformedData.push({
          sourceLabel: Policy,
          previousValue: policy.old_value.name,
          currentValue: policy.new_value.name,
        });
      }

      if (competency_assessment) {
        transformedData.push({
          sourceLabel: SelfAssessment,
          previousValue: competency_assessment.old_value.name,
          currentValue: competency_assessment.new_value.name,
        });
      }

      if (duration_type) {
        transformedData.push({
          sourceLabel: DurationType,
          previousValue: duration_type.old_value,
          currentValue: duration_type.new_value,
        });
      }

      if (duration_count) {
        transformedData.push({
          sourceLabel: DurationCount,
          previousValue: duration_count.old_value.toString(),
          currentValue: duration_count.new_value.toString(),
        });
      }

      if (effective_from) {
        transformedData.push({
          sourceLabel: EffectiveFrom,
          previousValue: effective_from.old_value,
          currentValue: effective_from.new_value,
        });
      }
      return {
        date: formatDateTime(logEntry.created_at),
        name: userNameMap ? userNameMap[logEntry?.created_by]?.user_name : null,
        data: transformedData,
      };
    });
  };

  return (
    <div>
      <SliderModal isOpen={showActivityLog} toggle={handleActivityModal}>
        <H2>{ActivityLogs}</H2>
        <Divider />
        <ActivityLogContainer logs={transformLogs(auditLogs)} />
      </SliderModal>
    </div>
  );
};

AuditLog.propTypes = {
  handleActivityModal: PropTypes.func.isRequired,
  auditLogs: PropTypes.array,
  showActivityLog: PropTypes.bool,
};

export default AuditLog;
