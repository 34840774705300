import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import { Btn, H4 } from "../../../../AbstractElements";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import ModuleCard from "../../../../CommonElements/ModuleCard";
import PassScoreCard from "../../../../CommonElements/PassScoreCard";
import CatlogAssessmentHeading from "../../../../container/CatlogAssessmentHeading";
import RemedialAction from "../../../../container/RemedialAction";
import FailedScoreCard from "../../../../container/ScoreCardContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getCourseContentByIds } from "../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  AssessmentType,
  AttemptOn,
  ComingSoon,
  Course,
  DownloadCertificate,
  EstimatedTimer,
  ModuleDetails,
  SelfAssessment,
  TotalDuration,
  YouFailed,
} from "../../../../utils/Constant";
import {
  assessmentTypesMap,
  capitalizeFirstLetter,
  examStatus,
  formatCustomDate,
  getOrdinalWord,
  showToast,
} from "../../../../utils/helper/helper";

import "./style.scss";

const Attempts = () => {
  const location = useLocation();
  const { state } = location;
  const dispatch = useAppDispatch();
  const { courseContentByIds } = useAppSelector(state => state.course);

  const [remedialActions, setRemedialActions] = useState(null);

  useEffect(() => {
    const courseIds = state.assessmentDetails?.module_results
      ?.flatMap(item => item?.remedial_action?.courses || [])
      .filter(course => course !== undefined);
    if (courseIds?.length > 0) {
      dispatch(
        getCourseContentByIds({
          id: courseIds,
        })
      );
    }
  }, []);

  useEffect(() => {
    const formattedData = state.assessmentDetails?.module_results?.map(
      module => {
        const remedialCourses = module?.remedial_action?.courses
          ?.map(courseId =>
            courseContentByIds.find(course => course.id === courseId)
          )
          .filter(course => course !== undefined);

        const mediaUrls = module?.remedial_action.media_urls;
        const remedialNote = module?.remedial_action.remedial_note;
        return {
          courses: remedialCourses,
          module_name: module.module_name,
          documents: mediaUrls,
          greeting: module.module_name,
          paths: mediaUrls,
          remedialNote: remedialNote,
        };
      }
    );

    const mediaUrlsData = formattedData.map(
      ({ documents, greeting, paths }) => ({
        documents,
        greeting,
        paths,
      })
    );

    const remedialNoteData = formattedData.map(
      ({ module_name, remedialNote }) => ({
        module_name,
        remedialNote,
      })
    );
    setRemedialActions({
      corseData: formattedData.map(({ courses, module_name }) => ({
        courses,
        module_name,
      })),
      literature: mediaUrlsData,
      remedialNote: remedialNoteData,
    });
  }, [courseContentByIds, state.assessmentDetails?.module_results]);

  const isSelfAssessment = () => {
    return state?.assessment?.assessment_type === assessmentTypesMap.competency;
  };
  const handleCertificate = () => {
    showToast(ComingSoon, "warning");
  };

  const hasRemedialAction = state?.assessmentDetails?.module_results?.some(
    module =>
      module.remedial_action && Object.keys(module.remedial_action).length > 0
  );

  return (
    <div className="page-body attempts">
      <CatlogAssessmentHeading
        heading={state?.assessment?.name}
        rows={[
          {
            columns: [
              {
                assign: AssessmentType,
                name: (() => {
                  if (
                    state?.assessment?.assessment_type ===
                      assessmentTypesMap.sga ||
                    state?.assessment?.assessment_type ===
                      assessmentTypesMap.course
                  ) {
                    return Course;
                  }

                  if (isSelfAssessment()) {
                    return SelfAssessment;
                  }

                  return capitalizeFirstLetter(
                    state?.assessment?.assessment_type
                  );
                })(),
                key: 1,
              },
              {
                assign: EstimatedTimer,
                name: state?.assessment?.estimated_time,
                key: 2,
              },
              {
                assign: TotalDuration,
                name: state?.assessment?.overall_question_count,
                key: 3,
              },
            ],
          },
        ]}
        dateRange={{
          startDate: formatCustomDate(state?.assessment?.start_date),
          endDate: formatCustomDate(state?.assessment?.end_date),
        }}
      />

      {state.assessmentDetails.status === examStatus.passed && (
        <PassScoreCard score={`${state.assessmentDetails.score}%`} />
      )}
      {state.assessmentDetails.status === examStatus.failed && (
        <FailedScoreCard
          score={state.assessmentDetails.score}
          message={YouFailed}
          attemptOn={`${getOrdinalWord(state.assessmentDetails.attempt_number)} ${AttemptOn} ${formatCustomDate(state.assessmentDetails?.start_time)}`}
        />
      )}

      <Card className="mt-2">
        <CardHeader>
          <H4>{ModuleDetails}</H4>
        </CardHeader>
        <CardBody className="mt-2">
          <Row>
            {state.assessmentDetails?.module_results.map((item, index) => (
              <Col xs="12" md="4" key={index} className="mb-3">
                <ModuleCard title={item.module_name} score={`${item.score}%`} />
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      {hasRemedialAction && (
        <RemedialAction remedialActions={remedialActions} />
      )}

      <FixedFooter>
        <Btn onClick={handleCertificate} color="primary">
          {DownloadCertificate}
        </Btn>
      </FixedFooter>
    </div>
  );
};

export default Attempts;
