import { toast, TypeOptions } from "react-toastify";

import {
  Course,
  FY,
  LiteratureReference,
  MultiSelection,
  OpenField,
  Other,
  SingleSelection,
  Competent,
  NotCompetent,
  PartialCompetent,
} from "../Constant";

export const showToast = (message: string, type: TypeOptions) => {
  try {
    toast.dismiss();
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      case "info":
      case "default":
      default:
        toast.info(message);
        break;
    }
  } catch (error) {
    console.error(error);
  }
};

export const scheduledDate = dateString => {
  const date = new Date(dateString);

  if (date) {
    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = String(date.getFullYear()).slice(-2);

    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
};

export const getTomorrowDate = () => {
  const requiredDate = new Date();
  requiredDate.setDate(requiredDate.getDate() + 1);
  return requiredDate;
};

export const getNextDay = (date: Date) => {
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
};

export function getRandomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const handleAllFilesDownload = async (urls: string[]) => {
  for (const url of urls) {
    if (url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement("a");
        const objectURL = URL.createObjectURL(blob);
        link.href = objectURL;
        link.setAttribute("download", url.split("/").pop() || "file");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectURL);
      } catch (error) {
        console.error(`Failed to download file from ${url}: `, error);
      }
    }
  }
};

export const richTextSupportedItems = [
  "undo",
  "redo",
  "paragraph",
  "italic",
  "bold",
  "blockQuote",
  "bulletedList",
  "numberedList",
];

export const validateValue = (
  name: string,
  value: any,
  allowSpecialChars?: boolean,
  isNumeric?: boolean,
  minValue?: number,
  maxValue?: number
) => {
  if (value === undefined || value === null || value === "") {
    return {
      isValid: false,
      errorMessage: `${name} cannot be empty`,
    };
  }

  if (isNumeric) {
    if (isNaN(Number(value))) {
      return { isValid: false, errorMessage: `${name} must be a number` };
    }

    const numValue = Number(value);
    if (minValue !== null && numValue < minValue) {
      return {
        isValid: false,
        errorMessage: `${name} must be greater than or equal to ${minValue}`,
      };
    }

    if (maxValue !== null && numValue > maxValue) {
      return {
        isValid: false,
        errorMessage: `${name} must be less than or equal to ${maxValue}`,
      };
    }
  }

  if (!allowSpecialChars && /[^a-zA-Z0-9]/.test(value)) {
    return {
      isValid: false,
      errorMessage: `${name} contains invalid characters`,
    };
  }

  return { isValid: true, errorMessage: null };
};

export const facilitatorDefaultData =
  "<ul><li>Without causing damage to components, equipment, and no injury to personnel</li><li>Using appropriate tooling, techniques, and materials</li><li>According to industry/enterprise guidelines, procedure, and policies</li><li>Using and interpreting correct information from the GMMCO specifications</li></ul>";

export const resourceAndReferenceDefaultData =
  "<p>Evidence of achieving this module learning outcomes at a knowledge level, is attained by a summative online assessment. The summative online assessment is open book and a minimum standard of 80% is required to deem the participant as competent.</p><p>Attainment of the participant performance at a skill level (hands-on) is achieved using assessment checklist provided in the Skill Competency Guide. This assessment is conducted by the reporting manager (or the person designated by the reporting manager) by direct observation of tasks and questioning on underpinning knowledge.</p>";

export const reviewDefaultData = "Verify the completion of,";

export const formatCustomDate = (dateString: string): string | undefined => {
  if (!dateString || dateString === "") {
    return;
  }
  const date = new Date(dateString);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  function getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
};

export const formatJSDate = date => {
  if (date) {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  return "";
};

export const getCurrentDate = () => {
  const date = new Date();

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  const currentDate = `${year}-${month}-${day}`;

  return currentDate;
};

export const sortCommentsByDate = (comments: CommentItem[]): CommentItem[] => {
  const commentsCopy = [...comments];
  return commentsCopy.sort(
    (firstComment, secondComment) =>
      new Date(secondComment.created_at).getTime() -
      new Date(firstComment.created_at).getTime()
  );
};

export const formatDate = (selectedDate: string | Date): string => {
  if (selectedDate instanceof Date) {
    const localDate =
      selectedDate.getFullYear() +
      "-" +
      String(selectedDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(selectedDate.getDate()).padStart(2, "0");
    return localDate;
  } else {
    if (selectedDate === "") {
      return;
    }
    const date = new Date(selectedDate);
    if (isNaN(date.getTime())) {
      return;
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
};

export const formatDateTime = (dateString: string): string | undefined => {
  const formattedDate = formatDate(dateString);
  if (!formattedDate) {
    return undefined;
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return undefined;
  }

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
  return `${formattedDate} ${formattedTime}`;
};

export const rmTabs = [
  { id: "new_request", label: "New" },
  { id: "draft", label: "Draft" },
  { id: "reject", label: "Rejected" },
];

export const tmRequestTabs = [
  { id: "new_request", label: "New" },
  { id: "reject", label: "Rejected" },
];

export const courseListTabs = [
  { id: "new_request", label: "New" },
  { id: "draft", label: "Draft" },
  { id: "submitted", label: "Submitted" },
  { id: "approved", label: "Approved" },
  { id: "published", label: "Published" },
  { id: "rejected", label: "Rejected" },
];
export const policyListTabs = [
  { id: "unmapped", label: "Unmapped" },
  { id: "mapped", label: "Mapped" },
];

export const curriculumTabs = [
  { id: "draft", label: "Draft" },
  { id: "published", label: "Published" },
];

export const questionBankTabs = [
  { id: "draft", label: "Draft" },
  { id: "published", label: "Published" },
];

export const assessmentCatlogTabs = [
  { id: "draft", label: "Draft" },
  { id: "published", label: "Published" },
];

export const assessmentCatlogDetailsTabs = [
  { id: "details", label: "Details" },
  { id: "schedule_overview", label: "Schedule Overview" },
  { id: "modification_request", label: "Modification Request" },
];

export const scheduledDetailsTabs = [
  { tabId: "assigned", title: "Assigned" },
  { tabId: "pending_review", title: "Pending Review" },
  { tabId: "extension_request", title: "Extension Request" },
];

export const scheduledCompetencyTabs = [
  { tabId: "assigned", title: "Assigned" },
  { tabId: "pending_review", title: "Pending Review" },
];

export const addQuestionBankTabs = [
  { id: "manual", label: "Manual Entry" },
  { id: "auto", label: "Upload Question Bank" },
];

export const addModulesTabs = [
  { title: "Questions", icon: "", tabId: "questions" },
  { title: "Remedial Action", icon: "", tabId: "remidial_actions" },
];

export const ilpLearnerTabs = [
  { id: "overview", label: "Overview" },
  { id: "assigned_ilp", label: "Assigned ILP" },
  { id: "self_enrollment", label: "Self enrollment" },
  { id: "certification", label: "Certification" },
  { id: "unmapped", label: "Unmapped" },
  { id: "sga", label: "SGA" },
  { id: "pending_skill_update", label: "Pending Skill Update" },
  { id: "assessment", label: "Assessment" },
  { id: "training_history", label: "Training History" },
];

export const participantFormTabs = [
  { title: "Select Users", icon: "", tabId: "select_users" },
  { title: "Manage Enrollments", icon: "", tabId: "manage_enrollments" },
];

export const participantTypesTab = [
  { label: "Enrolling Users", href: "", tabId: "enrolling_users" },
  { label: "Enrolled Users", href: "", tabId: "enrolled_users" },
];

export const courseTypeOptions = [
  { value: "", label: "Select" },
  { value: "ilt", label: "ILT" },
  { value: "vilt", label: "VILT" },
  { value: "webinar", label: "Webinar" },
  { value: "wbt", label: "WBT" },
];

export const frequencyRangeOptions = [
  { value: "weekly", label: "Weeks" },
  { value: "monthly", label: "Months" },
  { value: "yearly", label: "Years" },
];

export const suggestedDaysOptions = [
  { value: "daily", label: "Days" },
  { value: "weekly", label: "Weeks" },
  { value: "monthly", label: "Months" },
  { value: "yearly", label: "Years" },
];

export const frequencyType = {
  daily: "Days",
  weekly: "Weeks",
  monthly: "Months",
  yearly: "Years",
};

export const courseListOptions = [
  { value: "", label: "Select" },
  { value: "course_1", label: "Course 1" },
  { value: "course_2", label: "Course 2" },
];

export const trainerOptions = [
  { value: "trainer1", name: "trainer1", label: "Alice Johnson" },
  { value: "trainer2", name: "trainer2", label: "David Miller" },
  { value: "trainer3", name: "trainer3", label: "Sophie Wilson" },
  { value: "trainer4", name: "trainer4", label: "Michael Brown" },
];

export const smeOptions = [
  { value: "", label: "Select" },
  { value: "sme1", label: "Sophie Wilson" },
  { value: "sme2", label: "Michael Brown" },
];

export const skillOptions = [
  { value: "field", label: "Field" },
  { value: "ojt", label: "OJT" },
];

export const knowledgeOptions = [
  { value: "", label: "Select" },
  { value: "summative", label: "Summative" },
  { value: "formative", label: "Formative" },
];

export const assessmentTypesOptions = [
  { value: "knowledge", label: "Knowledge" },
  { value: "skill", label: "Skill" },
];

export const enrolmentOptions = [
  { value: "", label: "Select" },
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const selefAssesmentOptions = [
  { value: "", label: "Select" },
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const frequencyOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const proficiencyLevelKeyValue = [
  { id: 1, value: "pre_foundational", label: "Pre-Foundational" },
  { id: 2, value: "foundational", label: "Foundational" },
  { id: 3, value: "advanced", label: "Advanced" },
  { id: 4, value: "expert", label: "Expert" },
];

export const requestTypeOptions = [
  { value: "internal", label: "Internal" },
  { value: "external", label: "External" },
];

export const courseStepperData = [
  "Basic info",
  "Content details",
  "Key contact",
  "Others",
];
export const competencyStepperData = [
  "Basic info",
  "Recommended delivery",
  "Review & Assessment",
];

export const scheduleAssessmentStepperData = ["Set Dates", "Add Users"];

export const skillDocumentMap = {
  "Field Leaner Doc": "learner_document",
  "Field Trainer Doc": "trainer_document",
  "OJT Trainer Doc": "trainer_document",
  "OJT Learner Doc": "learner_document",
};

export function getEllipsedFileName(fileName: string, maxLength = 15): string {
  if (fileName) {
    if (fileName?.length <= maxLength) {
      return fileName;
    }
    const extensionMatch = fileName.match(/\.[^.]+$/);
    const fileExtension = extensionMatch ? extensionMatch[0] : "";
    const baseName = fileExtension
      ? fileName.substring(0, fileName.lastIndexOf(fileExtension))
      : fileName;
    const allowedBaseNameLength = maxLength - fileExtension.length - 3;
    const ellipsedBaseName =
      baseName.substring(0, allowedBaseNameLength) + "...";
    return ellipsedBaseName + fileExtension;
  }
}

export const getQuestionsType = (callback, index) => {
  return [
    {
      item: OpenField,
      onClick: () => callback(questionTypeMap[OpenField], index),
    },
    {
      item: SingleSelection,
      onClick: () => callback(questionTypeMap[SingleSelection], index),
    },
    {
      item: MultiSelection,
      onClick: () => callback(questionTypeMap[MultiSelection], index),
    },
  ];
};

export const competencyAnswersMap = {
  competent: Competent,
  not_competent: NotCompetent,
  partial_competent: PartialCompetent,
};

export const questionTypeMap = {
  [SingleSelection]: "single_select",
  [MultiSelection]: "multiple_choice",
  [OpenField]: "open_text",
};

export const selectionTypeMap = {
  single_select: SingleSelection,
  multiple_choice: MultiSelection,
  open_text: OpenField,
};

export function sanitizeString(input: string): string {
  return input.replace(/[^a-zA-Z0-9]+/g, "_").replace(/^_+|_+$/g, "");
}

export const PROFICIENCY_LEVEL = {
  PRE_FOUNDATIONAL: "pre_foundational",
  FOUNDATIONAL: "foundational",
  ADVANCED: "advanced",
  EXPERT: "expert",
};

export const mapListToOptions = (
  list: any[],
  labelKey: string,
  valueKey: string
): { value: string; label: string }[] => {
  const options = [{ id: valueKey, value: "", label: "Select" }];
  if (list?.length > 0) {
    list.forEach(element => {
      const elementObj = {
        id: element[valueKey].toString(),
        value: element[valueKey].toString(),
        label: element[labelKey],
      };
      options.push(elementObj);
    });
  }
  return options;
};

export const sgaDurationOptions = [
  { value: "", label: "Select" },
  { value: "daily", label: "Days" },
  { value: "weekly", label: "Weeks" },
  { value: "monthly", label: "Months" },
];

export const assessmentTypes = [
  { value: "standalone", label: "Standalone assessment" },
  { value: "competency", label: "Self Assessment" },
  { value: "course", label: "Course Assessment" },
];

export const assessmentCreationData = ["standalone", "course"];

export const frequencyTypes = [
  { value: "weekly", label: "Weeks" },
  { value: "monthly", label: "Months" },
  { value: "yearly", label: "Years" },
];

export const frequencyMapping = {
  daily: "Days",
  weekly: "Weeks",
  monthly: "Months",
  yearly: "Years",
};

export const courseFilterDropdownList = [
  { name: "ilt", value: "ILT", label: "ILT" },
  { name: "vilt", value: "VILT", label: "VILT" },
  { name: "webinar", value: "Webinar", label: "Webinar" },
  { name: "wbt", value: "WBT", label: "WBT" },
];

export const iltViltFilterDropdownList = [
  { name: "ilt", value: "ILT", label: "ILT" },
  { name: "vilt", value: "VILT", label: "VILT" },
];

export const courseRequestFilterDropdownList = [
  { name: "internal", value: "Internal", label: "Internal" },
  { name: "external", value: "External", label: "External" },
];

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const knowledgeHeader = [
  "Assessment Name",
  "Type",
  "Question Bank",
  "Questions",
  "Modules",
  "Assessment Duration (In minutes)",
  "Actions",
];
export const skillHeader = [
  "LO",
  "Type",
  "Learner Docs",
  "Trainer Docs",
  "Actions",
];

export const LoHeader = ["Outcome", "Function", "Action"];

export const generateFiscalYearSeries = (
  yearsBack: number,
  yearsForward: number
): string[] => {
  const currentYear = new Date().getFullYear();
  const fiscalYears: string[] = [];

  const formatYear = (year: number) => year.toString().padStart(2, "0");

  for (let i = -yearsBack; i <= yearsForward; i++) {
    const startYear = currentYear + i;
    const endYear = startYear + 1;
    fiscalYears.push(
      `${FY} ${formatYear(startYear % 100)}-${formatYear(endYear % 100)}`
    );
  }

  return fiscalYears;
};

export const getCurrentFiscalYear = (pastYears, futureYear) => {
  const currentYear = new Date().getFullYear();
  const fiscalYearSeries = generateFiscalYearSeries(pastYears, futureYear);
  const currentFiscalYear = `${FY} ${(currentYear % 100)
    .toString()
    .padStart(
      2,
      "0"
    )}-${((currentYear + 1) % 100).toString().padStart(2, "0")}`;

  const currentFiscalYearIndex = fiscalYearSeries.indexOf(currentFiscalYear);
  return currentFiscalYearIndex;
};

export const calculateTimeDifferenceInSeconds = (
  startDate: string,
  totalMinutes: number
): number => {
  const examStartDate = new Date(startDate);
  const examEndDate = new Date(
    examStartDate.getTime() + totalMinutes * 60 * 1000
  );
  const currentTime = new Date();
  const timeDifferenceInSeconds =
    (examEndDate.getTime() - currentTime.getTime()) / 1000;
  const time = Math.max(Math.round(timeDifferenceInSeconds), 0);
  return Number.isNaN(time) ? 0 : time;
};

export const questionTypes = {
  multiple_choice: "multiple_choice",
  single_select: "single_select",
  open_text: "open_text",
};

export const remedialActionTabs = [
  { title: Course, icon: "", tabId: "1" },
  { title: LiteratureReference, icon: "", tabId: "2" },
  { title: Other, icon: "", tabId: "3" },
];

export const downloadCsv = (name, res) => {
  const blob = new Blob([res], { type: "text/plain" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
};

export const getProficiencyListData = (
  callback,
  proficiency,
  proficiencyLevel
) => {
  const accordionItems = proficiency.map(level => {
    const item = proficiencyLevelKeyValue.find(
      entry => entry.label === level.level_name
    );
    const totalDuration = proficiencyLevel[item.value]?.course?.reduce(
      (total, course) => total + (course.duration ?? 0),
      0
    );
    return {
      id: level.id.toString(),
      key: level.id.toString(),
      accordionItemClass: "item-class",
      accordionHeaderClass: "header-class",
      courseTime: "",
      spanClass: "span-class",
      accordionHeading: level.level_name,
      icon: true,
      bodyText: callback(item.value, level.id),
      courseCount: true,
      totalDuration: `${convertMinutesToHours(totalDuration)} h`,
    };
  });

  return accordionItems;
};

export const PendingSkillTableHeader = [
  "LO",
  "Type",
  "Material File",
  "Your File",
  "Status",
  "Actions",
];

export const truncateText = (title: string, maxLength: number) => {
  if (title?.length <= maxLength) {
    return title;
  }
  return title?.slice(0, maxLength) + "...";
};
export const handleKeyDownOnNumericWithoutDecimal = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Delete"];
  if (
    !allowedKeys.includes(e.key) &&
    (isNaN(Number(e.key)) ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "e" ||
      e.key === "E")
  ) {
    e.preventDefault();
  }
};

export const handleKeyDownOnNumericWithDecimal = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const allowedKeys = [
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
    "Delete",
    ".",
  ];
  if (
    !allowedKeys.includes(e.key) &&
    (isNaN(Number(e.key)) ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "e" ||
      e.key === "E")
  ) {
    e.preventDefault();
  }
};

export const AssessmentOptions = [
  { value: "", label: "Select" },
  { value: "self", label: "Self" },
  { value: "standalone", label: "Standalone" },
];

export const AssessmentTabs = [
  { id: "current", label: "Active" },
  { id: "upcoming", label: "Upcoming" },
  { id: "past", label: "Past" },
  { id: "under_review", label: "Under review" },
];

export const calculateDayDifference = givenDateString => {
  if (!givenDateString) {
    return;
  }
  const givenDate = new Date(givenDateString);
  const today = new Date();
  givenDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  const timeDifference = givenDate.getTime() - today.getTime();
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return dayDifference > 0 ? dayDifference : 0;
};

export const getOrdinalWord = number => {
  const ordinalWords = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];

  return ordinalWords[number] || `${number}th`;
};

export const assessmentStatus = {
  inProgress: "in_progress",
  yetToStart: "yts",
  retake: "retake",
  underReview: "under_review",
  completed: "completed",
  passed: "passed",
  failed: "failed",
};

export const assessmentTypesMap = {
  competency: "competency",
  standalone: "standalone",
  sga: "sga",
  course: "course",
};

export const assessmentTypesNames = {
  competency: "Self",
  standalone: "Standalone",
  sga: "Course",
  course: "Course",
};

export const examStatus = {
  passed: "passed",
  failed: "failed",
};

export const assessmentTabStatus = {
  current: "current",
  underReview: "under_review",
  past: "past",
  upcoming: "upcoming",
};

export const initialChartData = {
  options: {
    chart: {
      id: "annotation-chart",
    },
    xaxis: {
      categories: [],
    },
  },
  series: [
    {
      name: "Series 1",
      data: [],
    },
  ],
};

export const convertMinutesToHours = (minutes: number): string => {
  const hours = (minutes / 60).toFixed(2);

  if (hours === "0.00" || isNaN(Number(hours))) {
    return "0";
  }

  return hours;
};

export const assessmentParticipantStatus = {
  yts: "Yet to start",
  in_progress: "In progress",
  failed: "Failed",
  passed: "Passed",
  under_review: "Under review",
  certification: "Certification",
  retake: "Retake",
  draft: "Draft",
  completed: "Completed",
};

export const assessmentTypeOptionsMap = {
  ojt: "OJT Assessment",
  field: "Field Assessment",
};

export const pendingStatus = ["Under review", "Draft"];

export const UserListTabs = [
  { id: "active", label: "Active" },
  { id: "suspended", label: "Suspended" },
];

export const userManagementTabs = [
  { id: "overview", label: "Overview" },
  { id: "assigned_ilp", label: "Assigned ILP" },
  { id: "self_enrollment", label: "Self enrollment" },
  { id: "unmapped", label: "Unmapped" },
  { id: "sga", label: "SGA" },
  { id: "pending_skill_update", label: "Pending Skill Update" },
  { id: "assessment", label: "Assessment" },
  { id: "training_history", label: "Training History" },
];
export const displayStatus = ["under_review", "draft"];

const countOccurrences = (responses: string[]) => {
  const counts = {
    competent: 0,
    not_competent: 0,
    partial_competent: 0,
  };
  responses.forEach(response => {
    if (counts[response] !== undefined) {
      counts[response]++;
    }
  });
  return counts;
};

const processGraphData = (
  trainerResponses: string[],
  learnerResponses: string[]
) => {
  const trainerCounts = countOccurrences(trainerResponses);
  const learnerCounts = countOccurrences(learnerResponses);

  const totalTrainerResponses =
    trainerCounts.competent +
    trainerCounts.not_competent +
    trainerCounts.partial_competent;

  const totalLearnerResponses =
    learnerCounts.competent +
    learnerCounts.not_competent +
    learnerCounts.partial_competent;

  const calculatePercentage = (count: number, total: number) => {
    return total > 0 ? Math.round((count / total) * 100) : 0;
  };

  return {
    categories: ["Competent", "Not Competent", "Partial Competent"],
    series: [
      {
        name: "Trainer Response",
        data: [
          calculatePercentage(trainerCounts.competent, totalTrainerResponses),
          calculatePercentage(
            trainerCounts.not_competent,
            totalTrainerResponses
          ),
          calculatePercentage(
            trainerCounts.partial_competent,
            totalTrainerResponses
          ),
        ],
      },
      {
        name: "Learner Response",
        data: [
          calculatePercentage(learnerCounts.competent, totalLearnerResponses),
          calculatePercentage(
            learnerCounts.not_competent,
            totalLearnerResponses
          ),
          calculatePercentage(
            learnerCounts.partial_competent,
            totalLearnerResponses
          ),
        ],
      },
    ],
  };
};

export const getChartData = (
  trainerResponses: string[],
  learnerResponses: string[]
) => {
  const { categories, series } = processGraphData(
    trainerResponses,
    learnerResponses
  );

  const options = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      max: 100,
      title: {
        text: "Percentage (%)",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return { options, series };
};

export const isDateLessThanOrEqualToToday = dateToCopare => {
  const expiresAtDate = new Date(dateToCopare + "T00:00:00Z");
  const today = new Date();
  const startOfTodayUTC = new Date(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())
  );
  return expiresAtDate <= startOfTodayUTC;
};

export const ilpCourseSummaryData = data => {
  return [
    {
      id: 1,
      icon: "graduation-cap",
      count: data.total_courses,
      label: "Total Course",
      infoData: [
        { courseName: "WBT", totalCount: data.course_types.total_courses.wbt },
        {
          courseName: "VILT",
          totalCount: data.course_types.total_courses.vilt,
        },
        { courseName: "ILT", totalCount: data.course_types.total_courses.ilt },
        {
          courseName: "Webinar",
          totalCount: data.course_types.total_courses.webinar,
        },
      ],
    },
    {
      id: 2,
      icon: "completed-tick",
      count: data.completed_courses,
      label: "Completed",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.completed_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.completed_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.completed_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.completed_courses.webinar,
        },
      ],
    },
    {
      id: 3,
      icon: "upcoming-calendar",
      count: data.upcoming_courses,
      label: "Upcoming",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.upcoming_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.upcoming_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.upcoming_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.upcoming_courses.webinar,
        },
      ],
    },
    {
      id: 4,
      icon: "scheduled-calendar",
      count: data.scheduled_courses,
      label: "Scheduled",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.scheduled_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.scheduled_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.scheduled_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.scheduled_courses.webinar,
        },
      ],
    },
    {
      id: 5,
      icon: "overdue-calendar",
      count: data.overdue_courses,
      label: "Overdue",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.overdue_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.overdue_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.overdue_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.overdue_courses.webinar,
        },
      ],
    },
  ];
};

export const calculateValuePercentage = (value: number, totalCourses) => {
  if (!totalCourses || totalCourses === 0) return 0;
  return Number(((value / totalCourses) * 100).toFixed(1));
};

export const multiProgressData = courseData => {
  const totalCourses = courseData.total_courses;
  const percentageData = [
    {
      id: courseData.id,
      title: "Completed",
      percentage: calculateValuePercentage(
        courseData.completed_courses,
        totalCourses
      ),
      className: "success-color",
    },
    {
      title: "Upcoming",
      percentage: calculateValuePercentage(
        courseData.upcoming_courses,
        totalCourses
      ),
      className: "warning-color",
    },
    {
      title: "Scheduled",
      percentage: calculateValuePercentage(
        courseData.scheduled_courses,
        totalCourses
      ),
      className: "assigned-color",
    },
    {
      title: "Overdue",
      percentage: calculateValuePercentage(
        courseData.overdue_courses,
        totalCourses
      ),
      className: "error-color",
    },
  ];

  return percentageData;
};
