import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { MUIIcons, H5, H2 } from "../../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  EDIT_COURSE_REQUEST_STATUS,
} from "../../../../Api/constants";
import { convertCoursePayloadToForm } from "../../../../Api/parser/courseRequest";
import Divider from "../../../../CommonElements/Divider";
import SliderModal from "../../../../CommonElements/SliderModal";
import Comments from "../../../../container/Comments";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  setNumberLevel,
  setShowFinish,
} from "../../../../ReduxToolkit/Reducers/RequestCourseWizardSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { Modify, RejectReason } from "../../../../utils/Constant";
import { hasPermissionToComponent } from "../../../../utils/helper/permission";

import RMDetails from "./RMDetails";

const RequestDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const [sliderModal, setSliderModal] = useState(false);
  const location = useLocation();
  const { usersList } = useAppSelector(state => state.user);
  const [userMap, setUserMap] = useState(null);
  const { courseRequestId } = location.state || null;
  const { courseRequestById } = useAppSelector(state => state.courseRequest);
  const [rejectedComments, setRejectedComments] = useState(null);

  const sliderToggle = () => {
    setSliderModal(!sliderModal);
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    setRejectedComments(null);
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    if (courseRequestById?.comment?.course_request?.length > 0) {
      setRejectedComments(courseRequestById?.comment?.course_request);
    }
  }, [dispatch, courseRequestById]);

  return (
    <div className="page-body">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{RejectReason}</H2>
        <Divider />
        <Comments userMap={userMap} comments={rejectedComments} />
      </SliderModal>
      <div>
        <Card className="p-2">
          <CardBody>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-end">
              <div className="d-flex align-items-center gap-2"></div>
              <div className="d-flex align-items-center gap-4">
                <MUIIcons
                  size={24}
                  onClick={sliderToggle}
                  className="primary-icon-color pointer"
                  iconName="ReportProblemOutlined"
                />
                <div
                  onClick={() => {
                    dispatch(setShowFinish(false));
                    dispatch(setNumberLevel(1));
                  }}
                  className="d-flex align-items-center gap-2 pointer"
                >
                  {hasPermissionToComponent("EDIT_REQUEST") &&
                    EDIT_COURSE_REQUEST_STATUS.includes(
                      courseRequestById?.status
                    ) && (
                      <Link
                        to="/course-management/new-request"
                        state={{
                          type: "edit",
                          data: convertCoursePayloadToForm(courseRequestById),
                        }}
                        className="d-flex gap-2"
                      >
                        <MUIIcons
                          size={24}
                          className="primary-icon-color"
                          iconName="EditOutlined"
                        />
                        <H5 className="primary-text-color mb-1">{Modify}</H5>
                      </Link>
                    )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="mt-4">
          <RMDetails userMap={userMap} courseRequestId={courseRequestId} />
        </div>
      </div>
    </div>
  );
};

export default RequestDetails;
