import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";

import { SgaRole } from "../../../Api/entities/SgaEntity";
import SearchBar from "../../../CommonElements/SearchBar";
import AssessmentModuleList from "../../../container/AssessmentModuleList";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch } from "../../../ReduxToolkit/Hooks";
import {
  getGradeList,
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getSgaList } from "../../../ReduxToolkit/Reducers/SgaSlice";
import {
  Duration,
  EffectiveFrom,
  Grade,
  Level,
  NoOfCourse,
  SelfAssessment,
  ReAttemptPolicy,
  Yes,
  No,
} from "../../../utils/Constant";
import {
  formatCustomDate,
  frequencyMapping,
} from "../../../utils/helper/helper";

interface TransformedData {
  columns: {
    assign: string;
    name: string;
    key: number;
  }[];
}

const transformDataForRole = (role: SgaRole): TransformedData[] => {
  const getSelfAssessmentName = level => {
    if (level.id && level.assessment_id) {
      return Yes;
    } else if (level.id && !level.assessment_id) {
      return No;
    }
    return "-";
  };
  return role.levels.map(level => ({
    columns: [
      {
        assign: Level,
        name: `${level.level_name}`,
        key: level.level_id,
      },
      {
        assign: SelfAssessment,
        name: getSelfAssessmentName(level),
        key: level.level_id,
      },
      {
        assign: NoOfCourse,
        name: level?.courses_count ? level?.courses_count?.toString() : "-",
        key: level.level_id,
      },
      {
        assign: Grade,
        name:
          level?.grades?.length > 0
            ? level.grades.map(item => item.name).join(", ")
            : "-",
        key: level.level_id,
      },
      {
        assign: Duration,
        name: level?.duration_count
          ? `${level?.duration_count?.toString()} ${frequencyMapping[level.duration_type]}`
          : "-",
        key: level.level_id,
      },
      {
        assign: EffectiveFrom,
        name: level.effective_from
          ? formatCustomDate(level.effective_from)
          : "-",
        key: level.level_id,
      },
      {
        assign: ReAttemptPolicy,
        name: level.policy_name ? level.policy_name : " -",
        key: level.level_id,
      },
    ],
  }));
};

const SgaList: React.FC = () => {
  const [listData, setListData] = useState<SgaRole[]>([]);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<SgaRole[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = listData.filter(role =>
        role.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setNoDataFound(filtered?.length === 0);
    } else {
      setFilteredData(listData);
    }
  }, [searchTerm, listData]);

  const fetchData = async (): Promise<void> => {
    try {
      const [
        rolesResponse,
        sgaResponse,
        proficiencyResponse,
        gradListResponse,
      ] = await Promise.all([
        dispatch(
          getRolesList({
            getMappedRole: "1",
          })
        ).unwrap(),
        dispatch(getSgaList()).unwrap(),
        dispatch(getProficiencyList()).unwrap(),
        dispatch(getGradeList()).unwrap(),
      ]);

      if (!rolesResponse || !proficiencyResponse) {
        setNoDataFound(true);
        return;
      }

      const combinedData: SgaRole[] = rolesResponse?.job_role?.map(
        (role: any) => ({
          role_id: role.id,
          label: role.name,
          levels: proficiencyResponse?.proficiency.map((level: any) => ({
            id: null,
            level_name: level.level_name,
            level_id: level.id,
            policy_id: "",
            policy_name: "",
            courses_count: null,
            grades: [],
            duration_count: null,
            effective_from: "",
            duration_type: "",
            assessment_id: null,
          })),
        })
      );

      const modifiedData = getUpdatedData(
        sgaResponse.data,
        combinedData,
        gradListResponse
      );

      setListData(modifiedData);
    } catch (error) {
      setNoDataFound(true);
    }
  };

  const handleEdit = (key: number, role: SgaRole) => {
    const findLevel = role?.levels?.find(item => item.level_id === key);
    navigate("/master-data/map-sga", {
      state: {
        title: role.label,
        role_id: role.role_id,
        level_id: key,
        id: findLevel.id,
        level: findLevel.level_name,
      },
    });
  };

  const getUpdatedData = (
    source: any[],
    target: SgaRole[],
    gradListResponse
  ): SgaRole[] => {
    const sourceRoleMap = {};
    source.forEach(sRole => {
      if (!sourceRoleMap[sRole.role_id]) {
        sourceRoleMap[sRole.role_id] = [];
      }
      sourceRoleMap[sRole.role_id].push(...sRole.levels);
    });

    return target.map(targetRole => {
      const sourceLevels = sourceRoleMap[targetRole.role_id] || [];

      return {
        ...targetRole,
        levels: targetRole.levels.map(targetLevel => {
          const sourceLevel = sourceLevels.find(
            sLevel => sLevel.level_id === targetLevel.level_id
          );
          const getValue = (sourceValue, targetValue) =>
            sourceValue ?? targetValue;

          if (sourceLevel) {
            const matchedGrades = gradListResponse?.grades?.filter(item =>
              sourceLevel?.grades?.includes(item.id)
            );
            const {
              policy_id,
              policy_name,
              courses_count,
              duration_count,
              effective_from,
              duration_type,
              id,
              assessment_id,
            } = sourceLevel;
            const {
              grades: targetGrades,
              policy_id: targetPolicyId,
              policy_name: targetPolicyName,
              courses_count: targetCoursesCount,
              duration_count: targetDurationCount,
              effective_from: targetEffectiveFrom,
              duration_type: targetDurationType,
              id: targetId,
              assessment_id: targetAssessmentId,
            } = targetLevel;

            return {
              ...targetLevel,
              policy_id: getValue(policy_id, targetPolicyId),
              policy_name: getValue(policy_name, targetPolicyName),
              courses_count: getValue(courses_count, targetCoursesCount),
              grades: matchedGrades?.length ? matchedGrades : targetGrades,
              duration_count: getValue(duration_count, targetDurationCount),
              effective_from: getValue(effective_from, targetEffectiveFrom),
              duration_type: getValue(duration_type, targetDurationType),
              id: getValue(id, targetId),
              assessment_id: getValue(assessment_id, targetAssessmentId),
            };
          }
          return targetLevel;
        }),
      };
    });
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody>
          <div
            className={"d-flex flex-column flex-md-row justify-content-between"}
          >
            <div className="d-flex justify-content-end align-items-center mt-sm-0 gap-3 ms-auto">
              <SearchBar onSearch={searchText => setSearchTerm(searchText)} />
            </div>
          </div>
        </CardBody>
      </Card>

      {filteredData.map(role => {
        const transformedData = transformDataForRole(role);
        return (
          <AssessmentModuleList
            key={role.role_id}
            heading={role.label}
            onEdit={key => handleEdit(key, role)}
            isEdit={true}
            headerRow={transformedData[0]?.columns}
            dataRows={transformedData.slice(1)}
          />
        );
      })}
      <Row className="mt-5">{noDataFound && <NoDataFound />}</Row>
    </div>
  );
};

export default SgaList;
