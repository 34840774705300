import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, H2, MUIIcons } from "../../../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  CompetencyFileKey,
} from "../../../../../Api/constants";
import Divider from "../../../../../CommonElements/Divider";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import SliderModal from "../../../../../CommonElements/SliderModal";
import Comments from "../../../../../container/Comments";
import CourseDetails from "../../../../../container/CourseDetails";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  downloadFile,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../ReduxToolkit/Reducers/CourseSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  Assessment,
  Back,
  BasicInfo,
  Classroom,
  ClassroomEnvironment,
  Comment,
  CompetencyFile,
  Condition,
  Dates,
  EnterComments,
  EstimatedDelivery,
  MediaDemonstration,
  Next,
  OtherPrerequisite,
  OtherReference,
  PersonalStatement,
  Practical,
  Prerequisite,
  RecommendedDelivery,
  Reference,
  Review,
  ReviewAssessment,
  Tools,
  TrainingAids,
  VersionNumber,
} from "../../../../../utils/Constant";
import {
  formatCustomDate,
  showToast,
  sortCommentsByDate,
} from "../../../../../utils/helper/helper";

const CompetancyStatement: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { courseContent } = useAppSelector(state => state.course);
  const [sliderModal, setSliderModal] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [isComentRead, setIsComentRead] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const [userMap, setUserMap] = useState(null);
  const [competencyBasicData, setCompetencyBasicData] = useState(null);
  const [recommondedData, setRecommondedData] = useState(null);
  const [assessmentData, setAssessmentData] = useState(null);
  const { courseId } = location.state || "";
  const [iconComments, setIconComments] = useState(null);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getCourseContent({ id: courseId }));
  }, [dispatch, courseId]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.competency_statement) {
      comment = sortCommentsByDate(courseContent.comment.competency_statement);
    }
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "competency_statement"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
    if (courseContent?.competency_statement) {
      const prerequisite = [];
      const tools = [];
      const trainingAids = [];
      const reference = [];

      const statement = courseContent?.competency_statement;
      statement?.prerequisite?.forEach(item => {
        prerequisite.push(item?.name);
      });

      statement?.tool_aid?.tool?.forEach((item: any) => {
        tools.push(item?.name);
      });

      statement?.tool_aid?.training_aid?.forEach((item: any) => {
        trainingAids.push(item?.name);
      });

      statement?.reference?.forEach(item => {
        reference.push(item?.name);
      });

      const competencyBasicData = [
        {
          details: [
            { assign: Dates, name: formatCustomDate(statement?.date) },
            { assign: VersionNumber, name: statement?.version },
            {
              assign: Condition,
              name: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement?.conditions,
                  }}
                />
              ),
            },
          ],
        },
        {
          details: [
            { assign: Prerequisite, name: prerequisite?.toString() },
            {
              assign: OtherPrerequisite,
              name: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement?.other_prerequisites,
                  }}
                />
              ),
            },
          ],
        },
      ];
      setCompetencyBasicData(competencyBasicData);
      const competencyRecommonded = [
        {
          details: [
            {
              assign: PersonalStatement,
              name: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement?.min_requirements,
                  }}
                />
              ),
            },
            {
              assign: MediaDemonstration,
              name: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement?.media_demonstration,
                  }}
                />
              ),
            },
            {
              assign: ClassroomEnvironment,
              name: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement?.classroom_lab,
                  }}
                />
              ),
            },
          ],
        },
        {
          details: [
            { assign: EstimatedDelivery, name: statement?.estimated_time },
            {
              assign: Classroom,
              name: statement?.class_room_ratio?.toString()?.replace(".", ":"),
            },
            {
              assign: Practical,
              name: statement?.practical_class_ratio
                ?.toString()
                ?.replace(".", ":"),
            },
          ],
        },
        {
          details: [
            {
              assign: Tools,
              name: tools?.toString(),
            },
            {
              assign: TrainingAids,
              name: trainingAids?.toString(),
            },
            {
              assign: Reference,
              name: reference?.toString(),
            },
          ],
        },
        {
          details: [
            {
              assign: OtherReference,
              name: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement?.other_references,
                  }}
                />
              ),
            },
          ],
        },
      ];
      setRecommondedData(competencyRecommonded);

      const assessmentBasicData = [
        {
          details: [
            {
              assign: Review,
              name: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement?.review,
                  }}
                />
              ),
            },
          ],
        },
        {
          details: [
            {
              assign: Assessment,
              name: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement?.assessment,
                  }}
                />
              ),
            },
          ],
        },
      ];
      setAssessmentData(assessmentBasicData);
    }
    setCommentList(comment);
  }, [courseContent]);

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "competency_statement",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "competency_statement",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  return (
    <div className="page-body pb-5">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card className="p-2">
        <CardBody>
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="d-flex gap-4">
              <MUIIcons
                size={24}
                className="primary-icon-color  mt-2 pointer"
                iconName="FileDownloadOutlined"
                onClick={() => {
                  downloadFile(
                    courseId,
                    CompetencyFileKey,
                    `${CompetencyFile}_${courseContent.course_name}_${courseContent.course_id}`
                  );
                }}
              />
              <div className="comment-icon-container">
                <MUIIcons
                  size={24}
                  onClick={sliderToggle}
                  className="primary-icon-color mt-2 pointer"
                  iconName="ChatBubbleOutlineOutlined"
                />
                {isComentRead != null && isComentRead && (
                  <span className="red-dot"></span>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className="mt-4 pb-5">
        {competencyBasicData !== null && (
          <CourseDetails
            level={2}
            data={competencyBasicData}
            headerText={BasicInfo}
          />
        )}
        {recommondedData !== null && (
          <CourseDetails
            level={4}
            data={recommondedData}
            headerText={RecommendedDelivery}
          />
        )}
        {assessmentData !== null && (
          <CourseDetails
            level={2}
            data={assessmentData}
            headerText={ReviewAssessment}
          />
        )}
      </div>
      <FixedFooter>
        <Row>
          <Col xs="auto">
            <Link
              to="/course-management/course-details"
              state={{ courseId: courseId }}
            >
              <Btn outline className="alert-light-primary" color="primary">
                {Back}
              </Btn>
            </Link>
          </Col>
          <Col xs="auto">
            <Link
              to="/course-management/course-details/lesson-plan"
              state={{ courseId: courseId }}
            >
              <Btn color="primary">{Next}</Btn>
            </Link>
          </Col>
        </Row>
      </FixedFooter>
    </div>
  );
};

export default CompetancyStatement;
