import PropTypes from "prop-types";
import { useEffect } from "react";
import { Card, CardBody } from "reactstrap";

import { H2 } from "../../../AbstractElements";
import CertificationListContainer from "../../../container/CertificationListContainer";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getCertificationList } from "../../../ReduxToolkit/Reducers/LearningPlanSlice";

const ILPCertification = ({ userId }) => {
  const { certificationsList } = useAppSelector(state => state.learningPlan);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCertificationList());
  }, [userId]);

  return (
    <div>
      <Card className="p-2">
        <CardBody>
          <div className="d-flex flex-row gap-4 align-items-center">
            <H2 className="text-black">Certification Acquired</H2>
            <p className="text-gray d-flex gap-2">
              Total certification :
              <strong className="text-black">
                {certificationsList?.certifications?.length}
              </strong>
            </p>
          </div>
          <div className="mt-4">
            {certificationsList?.certifications?.map((item, index) => (
              <CertificationListContainer
                key={`${index}${item.assessment_id}`}
                uniqueKey={`${index}${item.assessment_id}`}
                title={item.assessment_name}
                certifiedOn={item.issued_at}
                expiredOn={item.expires_at}
              />
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

ILPCertification.propTypes = {
  userId: PropTypes.number,
};
export default ILPCertification;
