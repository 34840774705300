import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Badges, MUIIcons, H4, P, ToolTip } from "../../AbstractElements";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import Divider from "../../CommonElements/Divider";
import {
  Approve,
  CompletedOn,
  Decline,
  ModifyYear,
  ScheduledOn,
} from "../../utils/Constant";
import { formatCustomDate, truncateText } from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import useIsMobile from "../../utils/helper/responsive";
import "./style.scss";

export interface LearningPlanCardData {
  courseId: string;
  domainName: string[];
  status: string;
  courseName: string;
  completedOn: string;
  scheduledOn: string;
  assignedBy: string;
  courseType: string;
  percentage?: string;
  badgeTextColor?: boolean;
  reAttemptCount?: number;
}

interface LearningPlanCardProps {
  data: LearningPlanCardData[];
  onDropdownClick?: (id: string, name: string) => void;
  showDropDown?: boolean;
}

const LearningPlanCourseCard: React.FC<LearningPlanCardProps> = ({
  data,
  onDropdownClick,
  showDropDown = false,
}) => {
  const isMobile = useIsMobile();
  const [tooltipCourseNameOpen, setTooltipCourseNameOpen] = useState<{
    [key: string]: boolean;
  }>({});

  const courseNameTooltip = (courseId: string) => {
    setTooltipCourseNameOpen(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId],
    }));
  };

  return (
    <Row className="mt-5">
      {data.map((item: any) => {
        return (
          <Col key={item.courseId} sm={12} lg={4}>
            <Card
              className={`pointer ${isMobile ? "" : "learningPlan-courseCard"}`}
            >
              <CommonCardHeader
                courseId={item.courseId}
                badge={item.status}
                headClass="card-no-border pb-0"
                title={item.domainName}
                status={item.status}
                badgeTextColor={item.badgeTextColor}
                reAttemptCount={item.reAttemptCount}
                showDrop={
                  (item.status === "waiting_rm" &&
                    showDropDown &&
                    hasPermissionToComponent(
                      "USER_MANAGEMENT.APPROVE_MODIFIED_YEAR"
                    )) ||
                  ((!item.status ||
                    item.status === "not_scheduled" ||
                    item.status === "over_due" ||
                    item.status === "overdue") &&
                    showDropDown &&
                    hasPermissionToComponent(
                      "USER_MANAGEMENT.MODIFY_YEAR_TO_COURSE"
                    ))
                }
                dropVal={
                  item.status === "waiting_rm" &&
                  hasPermissionToComponent(
                    "USER_MANAGEMENT.APPROVE_MODIFIED_YEAR"
                  )
                    ? [
                        { id: 1, courseId: item.id, name: Approve },
                        { id: 2, courseId: item.id, name: Decline },
                      ]
                    : [{ id: 1, courseId: item.id, name: ModifyYear }]
                }
                onDropClick={(id, name) => {
                  onDropdownClick(id, name);
                }}
              />
              <CardBody className="card-body d-flex flex-column">
                <div className="flex-grow-1">
                  <div>
                    <span id={`tooltip-course-name${item.courseId}`}>
                      <H4 className="header-text truncated-header">
                        {truncateText(item.courseName, 65)}
                      </H4>
                    </span>
                    <ToolTip
                      placement="top"
                      target={`tooltip-course-name${item.courseId}`}
                      isOpen={tooltipCourseNameOpen[item.courseId] || false}
                      toggle={() => courseNameTooltip(item.courseId)}
                    >
                      {item.courseName}
                    </ToolTip>
                  </div>
                </div>
                <div className="learningPlan-courseCard-footer">
                  <Divider />
                  <div className="d-flex justify-content-between mt-2">
                    <div className="d-flex gap-2">
                      <MUIIcons
                        className="text-gray mt-1"
                        size={18}
                        iconName="CalendarTodayOutlined"
                      />
                      {item.completedOn && (
                        <P className="text-muted mt-1">
                          {CompletedOn} {formatCustomDate(item.completedOn)}
                        </P>
                      )}

                      {item.scheduledOn && (
                        <P className="text-muted mt-1">
                          {ScheduledOn} {formatCustomDate(item.scheduledOn)}
                        </P>
                      )}
                    </div>
                    <div>
                      <Badges className="mt-1">{item.assignedBy}</Badges>
                      <Badges className="mt-1">{item.courseType}</Badges>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default LearningPlanCourseCard;
