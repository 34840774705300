import PropTypes from "prop-types";
import { Input, Table } from "reactstrap";

import "./style.scss";
import { Participant } from "../../utils/Constant";

const ParticipantAttendanceTable = ({ participants, dates }) => {
  return (
    <div className="participant-attendance-table">
      <div className="table-responsive">
        <Table bordered className="scrollable-table">
          <thead>
            <tr>
              <th>{Participant}</th>
              {dates.map(date => (
                <th key={date.label}>{date.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {participants.map(participant => (
              <tr key={participant.username}>
                <td>
                  <div>{participant.name}</div>
                  <div>@{participant.username}</div>
                </td>
                {dates.map(date => (
                  <td key={date.label}>
                    <div className="d-flex gap-1">
                      <Input
                        value={date.value}
                        name="role"
                        type="select"
                        className={
                          date.value === "A"
                            ? "dropdown-absent"
                            : "dropdown-present"
                        }
                      >
                        {["P", "A"].map(status => (
                          <option key={status}>{status}</option>
                        ))}
                      </Input>
                      <Input
                        value={date.value}
                        name="role"
                        type="select"
                        className={
                          date.value === "A"
                            ? "dropdown-absent"
                            : "dropdown-present"
                        }
                      >
                        {["P", "A"].map(status => (
                          <option key={status}>{status}</option>
                        ))}
                      </Input>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

ParticipantAttendanceTable.propTypes = {
  participants: PropTypes.array,
  dates: PropTypes.array,
};

export default ParticipantAttendanceTable;
