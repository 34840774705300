import { useState } from "react";
import DataTable from "react-data-table-component";
import {
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  CardBody,
} from "reactstrap";

import { H4, MUIIcons, P, ToolTip } from "../../../AbstractElements";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import ParticipantAttendanceTable from "../../../CommonElements/ParticipantAttendanceTable";
import SearchBar from "../../../CommonElements/SearchBar";
import ProjectDescription from "../../../container/ProjectDescription";
import {
  CourseID,
  CourseType,
  ProjectDeliveryMethods,
  ScheduledOn,
} from "../../../utils/Constant";

import "./style.scss";

const SamplePage = () => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(null);

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? null : rowId
    );
  };

  const handleEditItem = e => {
    e.stopPropagation();
  };

  const toggleTooltip = id => {
    setTooltipOpen(tooltipOpen === id ? null : id);
  };

  const columns = [
    {
      name: "Course name",
      selector: row => row.courseName,
      minWidth: "10rem",
      sortable: true,
      cell: row => (
        <div>
          <Input className="table-input" type="text" value={row.courseName} />
        </div>
      ),
    },
    {
      name: "Instructor",
      selector: row => (
        <div className="d-flex gap-1 align-items-center">
          {row.instructor}
          <span id={`tooltipIcon${row.id}`} className="ml-2 pointer">
            <MUIIcons
              className="pointer"
              iconName="InfoOutlined"
              size={18}
              onClick={() => null}
            />
          </span>
          <ToolTip
            placement="top"
            target={`tooltipIcon${row.id}`}
            isOpen={tooltipOpen === `tooltipIcon${row.id}`}
            toggle={() => toggleTooltip(`tooltipIcon${row.id}`)}
          >
            {row.instructor}
          </ToolTip>
        </div>
      ),
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: "Location",
      selector: row => row.location,
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: "Room",
      selector: row => row.room,
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: "Nomination Period",
      selector: row => row.nominationPeriod,
      minWidth: "15rem",
      sortable: true,
    },
    {
      name: "Course Period",
      selector: row => row.coursePeriod,
      minWidth: "15rem",
      sortable: true,
    },
    {
      name: "Available",
      selector: row => row.available,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: "Booked",
      selector: row => row.booked,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: "Waitlist",
      selector: row => row.waitlist,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: "Action",
      selector: row => row.id,
      sticky: "right",
      cell: row => (
        <div>
          <Dropdown
            isOpen={row.id === dropdownOpen}
            toggle={e => toggleDropdown(e, row.id)}
          >
            <DropdownToggle color="" className="three-dots">
              <div className="mb-1">&#x22EE;</div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={handleEditItem}>Edit</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Awaiting trainer confirmation..",
    },
    {
      id: 2,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Nomination Closed",
    },
    {
      id: 3,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Completed",
    },
    {
      id: 4,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Awaiting trainer confirmation..",
    },
    {
      id: 5,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Nomination Closed",
    },
    {
      id: 6,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Completed",
    },
    {
      id: 7,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Awaiting trainer confirmation..",
    },
    {
      id: 8,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Nomination Closed",
    },
    {
      id: 9,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Completed",
    },
    {
      id: 10,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Awaiting trainer confirmation..",
    },
    {
      id: 11,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Nomination Closed",
    },
    {
      id: 12,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Completed",
    },
  ];

  const participants = [
    {
      name: "Salvador Reynoso",
      username: "salvadoreynoso",
      attendance: {
        "17th Sept, Monday": "P",
        "18th Sept, Tuesday": "A",
        "19th Sept, Wednesday": "V",
        "20th Sept, Thursday": "P",
        "21st Sept, Friday": "A",
        "22st Sept, Friday": "A",
        "23st Sept, Friday": "P",
        "24st Sept, Friday": "A",
      },
    },
  ];

  const dates = [
    { label: "17th Sept, Monday", value: "P" },
    { label: "18th Sept, Tuesday", value: "A" },
    { label: "19th Sept, Wednesday", value: "P" },
    { label: "20th Sept, Thursday", value: "P" },
    { label: "21st Sept, Friday", value: "A" },
    { label: "22nd Sept, Saturday", value: "P" },
    { label: "23rd Sept, Sunday", value: "A" },
  ];

  return (
    <div className="page-body mb-5">
      <Card className="p-3">
        <div className="d-flex align-items-center">
          <div>
            <H4 className="comment-text">Scheduled list</H4>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
            <SearchBar onSearch={() => null} />
            <div className="mt-1">
              <FilterSliderModal
                dropdowns={[]}
                selectedFilterValues={[]}
                defaultFilterValues={[]}
                setSelectedFilterValues={() => null}
                setDefaultFilterValues={() => null}
                onDone={() => null}
                onClear={() => null}
              />
            </div>
          </div>
        </div>
        <CardBody className="p-2">
          <DataTable
            columns={columns}
            data={data}
            pagination
            fixedHeader
            striped
          />
        </CardBody>
      </Card>
      <div>
        <ProjectDescription
          title={ProjectDeliveryMethods}
          description={`Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.`}
          courseValues={[
            {
              label: CourseType,
              value: "ILT",
              id: CourseType,
            },
            {
              label: CourseID,
              value: "EEADVILT",
              id: CourseID,
            },
          ]}
          scheduledOn="10/08/2024"
        />
        <ParticipantAttendanceTable participants={participants} dates={dates} />
      </div>

      <Card>
        <CardBody>
          <div className="course-description__date-wrapper d-flex gap-2">
            <MUIIcons
              className="course-description__calender"
              size={18}
              iconName="CalendarTodayOutlined"
            />
            <P className="course-description__schedule-on m-0">
              {ScheduledOn} 10/08/2024
            </P>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default SamplePage;
