import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, H2 } from "../../AbstractElements";
import Divider from "../../CommonElements/Divider";
import FAB from "../../CommonElements/FAB";
import FixedFooter from "../../CommonElements/FixedFooter";
import CommonModal from "../../CommonElements/Modal";
import SliderModal from "../../CommonElements/SliderModal";
import AssessmentTimerCard from "../../container/AssessmentTimer";
import ExamResponseModal from "../../container/ExamResponseModal";
import Loader from "../../Layout/Loader/Loader";
import useCountdown, {
  useAppDispatch,
  useAppSelector,
} from "../../ReduxToolkit/Hooks";
import {
  getAssessmentByParticipantId,
  nextQuestion,
  previousQuestion,
  setQuestionIndex,
  setQuetions,
  setTotalQuetions,
  submitLearningAssessment,
} from "../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  Confirm,
  Confirmation,
  Next,
  Previous,
  Question,
  Questions,
  Submit,
  SubmitAssessmentAlert,
} from "../../utils/Constant";
import {
  assessmentStatus,
  calculateTimeDifferenceInSeconds,
  questionTypes,
} from "../../utils/helper/helper";
import {
  COURSE_ASSESSMENT,
  getFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "../../utils/helper/localStorageutils";
import useIsMobile from "../../utils/helper/responsive";

import AssessmentInfo from "./AssessmentInfo";
import Header from "./Header";
import FreeText from "./Questions/FreeText";
import MultiChoise from "./Questions/MultiChoise";
import SingleChoise from "./Questions/SingleChoise";

const LearningAssessment = () => {
  const [selectedData, setSelectedData] = useState([]);
  const [showExamDetailsInMobile, setShowExamDetailsInMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const { questions, currentQuestionIndex } = useAppSelector(
    state => state.assessment
  );
  const { loading } = useAppSelector(state => state.loader);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const time = useCountdown(
    calculateTimeDifferenceInSeconds(
      questions?.start_time,
      questions?.total_duration
    ),
    () => {
      handleSubmit();
    }
  );

  useEffect(() => {
    const existingState = getFromLocalStorage(COURSE_ASSESSMENT);
    if (
      state &&
      existingState?.questions?.participant_id === state?.participantId
    ) {
      dispatch(setQuestionIndex(0));
      if (existingState.questions) {
        dispatch(setQuetions(existingState.questions));
        dispatch(setTotalQuetions(existingState?.questions?.questions?.length));
      }
      const answers = existingState?.answers;
      if (answers?.length > 0) {
        setSelectedData(existingState.answers);
      }
    } else {
      dispatch(setQuestionIndex(0));
      dispatch(getAssessmentByParticipantId({ id: state?.participantId })).then(
        response => {
          if (!response?.payload) {
            return navigate("/assessment-list");
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    const handleContextMenu = e => e.preventDefault();
    const handleCopy = e => e.preventDefault();
    const handleCut = e => e.preventDefault();
    const handlePaste = e => e.preventDefault();
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
      }
    };
    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("copy", handleCopy);
    document.addEventListener("cut", handleCut);
    document.addEventListener("paste", handlePaste);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("cut", handleCut);
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (selectedData?.length > 0) {
      const existingState = getFromLocalStorage(COURSE_ASSESSMENT);
      if (existingState) {
        existingState.answers = selectedData;
        saveToLocalStorage(COURSE_ASSESSMENT, existingState);
      }
    }
  }, [selectedData]);

  const isMultiChecked = (option_id: number, question_id: number): boolean => {
    const response = selectedData.find(
      response => response.question_id === question_id
    );
    return response ? response.selected_option_id.includes(option_id) : false;
  };

  const handleMultiSelectChange = (e, option_id, question_id) => {
    setSelectedData(prevResponses => {
      const existingResponse = prevResponses.find(
        response => response.question_id === question_id
      );

      if (existingResponse) {
        const updatedOptionIds = e.target.checked
          ? [...existingResponse.selected_option_id, option_id]
          : existingResponse.selected_option_id.filter(id => id !== option_id);
        return prevResponses.map(response =>
          response.question_id === question_id
            ? { ...response, selected_option_id: updatedOptionIds }
            : response
        );
      } else {
        return [
          ...prevResponses,
          { question_id, selected_option_id: [option_id] },
        ];
      }
    });
  };

  const handleSingleSelectChange = (
    selected_option_id: number,
    question_id: number
  ) => {
    setSelectedData(prevResponses => {
      const updatedResponses = prevResponses.filter(
        response => response.question_id !== question_id
      );
      return [
        ...updatedResponses,
        { question_id, selected_option_id: [selected_option_id] },
      ];
    });
  };

  const isSingleChecked = (option_id: number, question_id: number): boolean => {
    const response = selectedData.find(
      response => response.question_id === question_id
    );

    return response ? response?.selected_option_id?.includes(option_id) : false;
  };

  const handleTextChange = (question_id: number, answer_text: string) => {
    setSelectedData(prevResponses => {
      const updatedResponses = prevResponses.filter(
        response => response.question_id !== question_id
      );
      return [...updatedResponses, { question_id, answer_text }];
    });
  };

  const getTextAnswer = (question_id: number): string => {
    const response = selectedData.find(
      response => response.question_id === question_id
    );
    return response ? response.answer_text : "";
  };

  const renderQuestionComponent = (
    question: ExamQuestion,
    questionNumber: string
  ) => {
    const question_text = question?.question_text;
    const question_type = question?.question_type;
    const options = question?.options;

    switch (question_type) {
      case questionTypes.multiple_choice:
        return (
          <MultiChoise
            questionId={question.question_id}
            title={question_text}
            questionNumber={questionNumber}
            options={options}
            handleChange={handleMultiSelectChange}
            isChecked={isMultiChecked}
            mediaUrl={question.media_url}
          />
        );

      case questionTypes.single_select:
        return (
          <SingleChoise
            questionId={question.question_id}
            title={question_text}
            questionNumber={questionNumber}
            options={options}
            handleChange={handleSingleSelectChange}
            isChecked={isSingleChecked}
            mediaUrl={question.media_url}
          />
        );

      case questionTypes.open_text:
        return (
          <FreeText
            questionId={question.question_id}
            title={question_text}
            questionNumber={questionNumber}
            handleChange={handleTextChange}
            value={getTextAnswer(question.question_id)}
            mediaUrl={question.media_url}
          />
        );

      default:
        return null;
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.questions.length - 1) {
      dispatch(nextQuestion());
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      dispatch(previousQuestion());
    }
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModal(false);
  };

  const handleSubmit = async () => {
    const payload = {
      participant_id: state.participantId,
      responses: selectedData,
    };
    const response = await dispatch(
      submitLearningAssessment({ learningAssessment: payload })
    );

    if (response.payload) {
      removeFromLocalStorage(COURSE_ASSESSMENT);
    }

    if (response?.payload?.data?.status === assessmentStatus.underReview) {
      handleCloseConfirmModal();
      handleModal();
    } else if (response?.payload?.data) {
      return navigate("/assessment-details", {
        state: {
          assessmentDetails: {
            ...response?.payload?.data,
            assessment_name: questions.assessment_name,
          },
        },
      });
    }
  };

  const handleDetailsInMobile = () => {
    setShowExamDetailsInMobile(prevState => !prevState);
  };

  const handleModal = () => {
    setShowModal(prevState => !prevState);
  };

  const handleDone = () => {
    handleModal();
    return navigate("/assessment-list");
  };

  const handleConfirmModal = () => {
    setIsConfirmModal(prevState => !prevState);
  };

  return (
    <>
      <Header name={questions?.assessment_name} />
      {loading && <Loader />}

      <CommonModal
        sizeTitle={Confirmation}
        modalBodyClassName=""
        isOpen={isConfirmModal}
        toggle={handleConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handleSubmit}
        primaryBtnText={Confirm}
      >
        {SubmitAssessmentAlert}
      </CommonModal>
      <Row className="learning-assessment justify-content-center">
        {isMobile && (
          <Col xs="12">
            <AssessmentTimerCard time={time} />
          </Col>
        )}
        <Col xs="12" md="7" className="mb-4">
          <Card>
            <CardBody>
              {questions?.questions?.length > 0 &&
                renderQuestionComponent(
                  questions?.questions[currentQuestionIndex],
                  `${Question} ${currentQuestionIndex + 1}`
                )}
            </CardBody>
          </Card>
        </Col>
        {!isMobile && (
          <Col xs="12" md="4">
            <AssessmentInfo selectedData={selectedData} time={time} />
          </Col>
        )}
      </Row>
      <FAB
        onClick={handleDetailsInMobile}
        title={Questions}
        buttonType="dark d-md-none d-lg-none"
      />

      {isMobile && (
        <SliderModal
          isOpen={showExamDetailsInMobile}
          toggle={handleDetailsInMobile}
        >
          <H2>{Questions}</H2>
          <Divider />
          <AssessmentInfo selectedData={selectedData} time={time} />
        </SliderModal>
      )}

      <ExamResponseModal
        examName={state?.name}
        isOpen={showModal}
        handleModal={handleDone}
      />

      <FixedFooter>
        {currentQuestionIndex > 0 && (
          <Btn
            onClick={handlePrevious}
            outline
            className="alert-light-primary"
            color="primary"
          >
            {Previous}
          </Btn>
        )}
        {currentQuestionIndex < questions?.questions?.length - 1 ? (
          <Btn onClick={handleNext} color="primary">
            {Next}
          </Btn>
        ) : (
          <Btn onClick={handleConfirmModal} color="primary">
            {Submit}
          </Btn>
        )}
      </FixedFooter>
    </>
  );
};

export default LearningAssessment;
