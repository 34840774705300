import { Link } from "react-router-dom";

import { MUIIcons, LI } from "../../../../AbstractElements";
import { profilesMessage } from "../../../../Data/LayoutData/HeaderData";
import { removeAllLocalStorage } from "../../../../utils/helper/localStorageutils";

const ProfileBox = () => {
  const handleClick = (name: string) => {
    if (name === "Log Out") {
      removeAllLocalStorage();
    }
  };
  return (
    <div>
      {profilesMessage?.map(data => (
        <LI key={data.id}>
          <Link
            className="d-flex justy-content-center"
            to={data.link}
            onClick={() => handleClick(data.name)}
          >
            <MUIIcons
              className="dropdown-item-icon"
              size={24}
              iconName={data.icon}
            />
            <span className="m-1">{data.name} </span>
          </Link>
        </LI>
      ))}
    </div>
  );
};

export default ProfileBox;
