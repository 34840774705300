import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../AbstractElements";
import { policyTabsMap } from "../../Api/constants";
import JustifyTabs from "../../CommonElements/JustifyTabs";
import CommonModal from "../../CommonElements/Modal";
import SearchBar from "../../CommonElements/SearchBar";
import AssessmentModuleList from "../../container/AssessmentModuleList";
import NoDataFound from "../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  deleteReAttemptPolicy,
  getReAttemptPolicyList,
} from "../../ReduxToolkit/Reducers/PolicySilce";
import {
  AddPolicy,
  Confirm,
  Count,
  DeleteReAttemptPolicy,
  DeleteReAttemptPolicyHeader,
  Enable,
  MinimumDaysBetweenReAttempts,
  NA,
  Name,
  PassingPercentage,
  ReAttempt,
  Score,
} from "../../utils/Constant";
import { policyListTabs } from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import useIsMobile from "../../utils/helper/responsive";

const PolicyList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { mFrom } = location.state || {};
  const { policyList } = useAppSelector(state => state.policy);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const isMobile = useIsMobile();
  const [policyIdToBeDeleted, setPolicyIdToBeDeleted] = useState(null);
  const [activeTab, setActiveTab] = useState<string>(
    mFrom === "mapped" ? "mapped" : "unmapped"
  );
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);

  useEffect(() => {
    fetchPolicy(activeTab);
  }, []);

  const fetchPolicy = (tabId: string) => {
    dispatch(getReAttemptPolicyList({ type: policyTabsMap[tabId] })).then(
      response => {
        if (response?.payload?.policy?.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
      }
    );
  };
  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    fetchPolicy(tabId);
  };

  const deletePolicy = (key: number) => {
    if (policyIdToBeDeleted) {
      dispatch(deleteReAttemptPolicy({ id: key })).then(() => {
        setPolicyIdToBeDeleted(null);
        fetchPolicy("unmapped");
      });
    }
  };

  const handleSearch = (query: string) => {
    const filteredPolicies = policyList?.policy?.filter(policy =>
      policy.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredPolicies || []);
    setNoDataFound(!filteredPolicies || filteredPolicies?.length === 0);
  };

  const editPolicy = (key: number) => {
    const policyToBeEdited = policyList.policy.find(
      policy => policy.id === key
    );
    navigate("/master-data/add-policy", {
      replace: false,
      state: { policyDetail: policyToBeEdited, edit: true, source: activeTab },
    });
  };

  const toggleDeleteConfirmModal = () => {
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);
  };

  const renderPolicyList = (policyList: any) => {
    if (policyList?.length > 0) {
      const constructColumn = (assign: string, name: string, key: number) => ({
        assign,
        name,
        key,
      });
      const getReAttemptRangeScore = (policy: any) => {
        if (policy.max_score && policy.min_score) {
          return `${policy.min_score}-${policy.max_score}`;
        } else {
          return NA;
        }
      };
      const constructedData = policyList.map(policy => {
        return {
          columns: [
            constructColumn(Name, policy.name, policy.id),
            constructColumn(
              PassingPercentage,
              policy.pass_percentage != null
                ? policy.pass_percentage.toString()
                : "-",
              policy.id
            ),
            constructColumn(
              `${ReAttempt} ${Enable}`,
              policy.is_reattempt ? "Yes" : "No",
              policy.id
            ),
            constructColumn(
              `${ReAttempt} ${Count}`,
              policy.no_of_attempt != null
                ? policy.no_of_attempt.toString()
                : "-",
              policy.id
            ),
            constructColumn(
              `${ReAttempt} ${Score}`,
              getReAttemptRangeScore(policy),
              policy.id
            ),
            constructColumn(
              MinimumDaysBetweenReAttempts,
              policy.cooling_period != null
                ? policy.cooling_period.toString()
                : "-",
              policy.id
            ),
          ],
        };
      });

      return (
        <div className="m-5">
          <AssessmentModuleList
            heading={""}
            onEdit={key => {
              editPolicy(key);
            }}
            onDelete={key => {
              setPolicyIdToBeDeleted(key);
              toggleDeleteConfirmModal();
            }}
            isEdit={true}
            isDelete={activeTab === "unmapped"}
            headerRow={constructedData[0]?.columns}
            dataRows={constructedData.slice(1)}
          />
        </div>
      );
    } else return <></>;
  };

  const renderPolicyContent = () => {
    if (noDataFound) {
      return <NoDataFound />;
    }

    if (searchResults?.length > 0) {
      return renderPolicyList(searchResults);
    }

    return renderPolicyList(policyList.policy);
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteReAttemptPolicyHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={() => {
          deletePolicy(policyIdToBeDeleted);
          toggleDeleteConfirmModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteReAttemptPolicy}
      </CommonModal>
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={policyListTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar onSearch={handleSearch} />
              {hasPermissionToComponent("ADD_POLICY") && (
                <Link
                  to={"/master-data/add-policy"}
                  state={{ policyDetail: {}, edit: false }}
                >
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                  >
                    {AddPolicy}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderPolicyContent()}</Row>
    </div>
  );
};

export default PolicyList;
